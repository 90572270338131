import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Grid
} from '@material-ui/core';
import AuthContext from '../context/AuthContext'
import * as ReactBootStrap from 'react-bootstrap';
import useStyles from './styles/CommonStyles';

const PreparationPage = ({onPageProps }) => {
    const history = useHistory();
    let [loading, setLoading] = useState(true)
    let [created, setCreated] = useState(false)
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')

    let {getRefreshToken} = useContext(AuthContext)
    const authToken = localStorage.getItem('authTokens');


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)

    }, [])

    const startAssemblySequence = (event) => {
        console.log('Starting assembly sequence generation...');
        onPageProps(3)
        history.replace('/header');
    };

    const createSinglePartStudio = async() => {
        setLoading(false)
        try{
            await axios({
                method: 'get',
                url: `/api/create-temporary-single-part-studio/`,
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'authTokens': authToken,
                    'refreshTokens': localStorage.getItem("refresh_token")
                }
            }).then(response => {
                localStorage.setItem('partStudio', response.data["partStudioId"])
                localStorage.setItem("partStudioTemporary", response.data["partStudioId"])
                setLoading(true)
                setCreated(true)}
        );
        } catch (e){
            alert("Failed to create a new single part studio. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            setLoading(true)
        }
    }

    const classes = useStyles();

    return (
        <div xs={12} style={{marginTop: '25%', textAlign: 'center'}}>

            <div className="create-new-objects" style={{ marginLeft: '10%', width: '80%', marginTop:'6%'}}>
                    {created && loading ?
                        <Grid container>
                            <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                                <Button className={classes.centerButton} mt={2} onClick={(evt) => startAssemblySequence()}  color="primary" type="button" variant="contained">
                                    Start Assembly Sequence Generation
                                </Button>                        
                            </Grid>
                            <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                            <Button className={classes.centerButton} mt={2} onClick={(evt) => history.replace('/header')}  color="primary" type="button" variant="contained">
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                    : loading ?
                        <Grid container>
                            <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                                <Button className={classes.centerButton} mt={2} onClick={(evt) => createSinglePartStudio()}  color="primary" type="button" variant="contained">
                                    Create a part studio
                                </Button>
                            </Grid>
                            <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                                <Button className={classes.centerButton} mt={2} onClick={(evt) => history.replace('/header')}  color="primary" type="button" variant="contained">
                                    Back
                                </Button>
                            </Grid>
                        </Grid>
                    : !loading ?
                    <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                        <ReactBootStrap.Spinner animation="border" style={{marginLeft: "5px", marginTop:'5%'}} />
                    </Grid>
                    : <></>
                    }
            </div>

        </div>
    )
};

export default PreparationPage;