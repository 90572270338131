import React, { useState, useEffect, useRef } from 'react';
import * as TWEEN from '@tweenjs/tween.js';
import axios from 'axios';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as ReactBootStrap from 'react-bootstrap';

const VisualizationComponent = ({ documentId, workspaceId, elementId, authToken, sequence, redirectErrorProps = console.error }) => {
  const [loading, setLoading] = useState(true);
  const [assemblyData, setAssemblyData] = useState(null);
  const viewerRef = useRef(null);
  const meshesRef = useRef([]);
  const [isAssembled, setIsAssembled] = useState(true);
  const scene = useRef(new THREE.Scene()).current;
  const camera = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)).current;
  const renderer = useRef(new THREE.WebGLRenderer({ antialias: true })).current;
  const controls = useRef(new OrbitControls(camera, renderer.domElement)).current;

  useEffect(() => {
    if (documentId && workspaceId && elementId && authToken) {
      fetchAndLoadAssemblyData();
    }
  }, [documentId, workspaceId, elementId, authToken]);

  useEffect(() => {
    console.log('Sequence Data:', sequence);
  }, [sequence]);


  useEffect(() => {
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000);
    document.body.appendChild(renderer.domElement);
    controls.update();

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.4);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.7);
    directionalLight.position.set(-1, 2, 4);
    scene.add(directionalLight);
    const hemisphereLight = new THREE.HemisphereLight(0xddddff, 0x202020, 0.6);
    scene.add(hemisphereLight);
    camera.position.z = 5;

    animate();

    return () => {
      document.body.removeChild(renderer.domElement);
    };
  }, []);

  useEffect(() => {
    meshesRef.current.forEach(mesh => {
      const targetPosition = isAssembled ? mesh.originalPosition : new THREE.Vector3(
        mesh.originalPosition.x + (Math.random() - 0.5) * 0.5,
        mesh.originalPosition.y + (Math.random() - 0.5) * 0.5,
        mesh.originalPosition.z + (Math.random() - 0.5) * 0.5
      );
      mesh.position.lerp(targetPosition, 0.05);
    });
  }, [isAssembled]);

  const setupViewer = (partsData) => {
    const loader = new GLTFLoader();
    const gltfPromises = partsData.map(data => loader.parseAsync(data.gltfUrl, ''));
    Promise.all(gltfPromises).then(gltfs => {
      gltfs.forEach((gltf, index) => {
        const partId = partsData[index].partId;  // Retrieve the part ID
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            child.name = partId;  // Set the mesh name to the part ID
            child.originalPosition = child.position.clone(); // Save the original position
            scene.add(child);
            meshesRef.current.push(child); // Store mesh in ref for easy access later
            console.log(`Mesh added: ${child.name}`);
          }
        });
      });
      renderer.render(scene, camera); // Update the scene
    }).catch(error => {
      console.error('An error occurred while parsing the GLTF models:', error);
      viewerRef.current.innerHTML = '<div>Error loading 3D models</div>';
    });
  };
  
  
  
  
  
  const animate = () => {
    requestAnimationFrame(animate);
    TWEEN.update();
    renderer.render(scene, camera);
    controls.update();
  };

  const toggleModel = () => {
    setIsAssembled(!isAssembled);
    if (isAssembled) {
      disassembleParts();
    } else {
      assembleParts();
    }
  };

  const disassembleParts = () => {
    if (!sequence || sequence.length === 0) {
      console.error("No sequence data to process.");
      return;
    }
  
    const flatSequence = flattenSequence(sequence);
    flatSequence.forEach((item, index) => {
      if (!item || item.length < 2) {
        console.error("Invalid part data:", item);
        return;
      }
  
      const partId = item[0]; // Assuming the first element is the part identifier
      const mesh = scene.getObjectByName(partId);
  
      if (!mesh || !mesh.position) {
        console.error(`Mesh not found or position undefined for part ID: ${partId}`);
        return;
      }
  
      const scatterPosition = {
        x: mesh.position.x + (Math.random() - 0.5) * 0.5,
        y: mesh.position.y + (Math.random() - 0.5) * 0.5,
        z: mesh.position.z + (Math.random() - 0.5) * 0.5
      };
  
      animatePart(mesh, scatterPosition, index);
    });
  };

  

  const assembleParts = () => {
    if (!sequence || sequence.length === 0) {
      console.error("Sequence data is missing or empty.");
      return;
    }
  
    const flatSequence = flattenSequence(sequence);
    flatSequence.forEach((item, index) => {
      if (!item || item.length < 2 || !item[0]) {
        console.error("Invalid part data or missing identifier:", item);
        return;
      }
  
      const partId = item[0];  // Assuming the first element is the unique identifier for the part.
      const mesh = scene.getObjectByName(partId);
  
      if (!mesh) {
        console.error(`Mesh not found for part ID: ${partId}`);
        return;
      }
  
      if (!mesh.position) {
        console.error(`Position attribute not defined for mesh: ${partId}`);
        return;
      }
  
      // Assuming the desired position is stored somewhere or calculated.
      const targetPosition = { x: 0, y: 0, z: 0 }; // Example target position. Update as necessary.
  
      animatePart(mesh, targetPosition, index);
    });
  
    setIsAssembled(true);
  };
  
  

  
  const flattenSequence = (sequence) => {
    const flatSequence = [];
    const flatten = (items) => {
      items.forEach(item => {
        if (Array.isArray(item)) {
          if (isValidPartData(item)) {
            flatSequence.push(item);
          } else {
            flatten(item); // Recurse if it's a nested array
          }
        }
      });
    };
    flatten(sequence);
    return flatSequence;
  };

  
  const isValidPartData = (partData) => {
    return Array.isArray(partData) && partData.length >= 2 && typeof partData[0] === 'string';
  };
  



  const fetchAndLoadAssemblyData = async () => {
    try {
      const partStudioElementId = await fetchPartStudioElementId();
      const assemblyUrl = `http://localhost:8000/api/get-private-content/?url=${encodeURIComponent(`https://cad.onshape.com/api/v1/assemblies/d/${documentId}/w/${workspaceId}/e/${elementId}`)}`;
      const assemblyResponse = await axios.get(assemblyUrl, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      const assemblyData = assemblyResponse.data;
  
      const partsWithGltfUrls = await Promise.all(assemblyData.parts.map(async (part) => {
        const partUrl = `http://localhost:8000/api/get-private-content/?url=${encodeURIComponent(`https://cad.onshape.com/api/v6/parts/d/${documentId}/w/${workspaceId}/e/${partStudioElementId}/partid/${part.partId}/gltf?rollbackBarIndex=-1&outputSeparateFaceNodes=false&outputFaceAppearances=false`)}`;
        try {
          const partResponse = await axios.get(partUrl, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
          return { partId: part.partId, gltfUrl: partResponse.data.url || partResponse.data };
        } catch (error) {
          console.error(`Error fetching GLTF for part ${part.partId}:`, error);
          return { partId: part.partId, gltfUrl: null };
        }
      }));
  
      const updatedAssemblyData = {
        ...assemblyData,
        parts: partsWithGltfUrls,
      };
  
      setAssemblyData(updatedAssemblyData);
      setupViewer(partsWithGltfUrls);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching and loading assembly data:', error);
      redirectErrorProps(error);
      setLoading(false);
    }
  };
  

  const fetchPartStudioElementId = async () => {
    try {
      const url = `http://localhost:8000/api/get-private-content/?url=${encodeURIComponent(`https://cad.onshape.com/api/documents/d/${documentId}/w/${workspaceId}/elements`)}`;
      const response = await axios.get(url, { headers: { Authorization: `Bearer ${authToken}` } });
      const elementsData = response.data;
      const partStudioElement = elementsData.find(element =>
        (element.type && element.type.toLowerCase() === 'partstudio') ||
        (element.elementType && element.elementType.toLowerCase() === 'partstudio')
      );
      if (!partStudioElement) throw new Error('Part studio element not found');
      return partStudioElement.id;
    } catch (error) {
      console.error('Error fetching part studio element ID:', error);
      throw error;
    }
  };

 

const animatePart = (mesh, position, index) => {
    new TWEEN.Tween(mesh.position)
        .to({ x: position.x, y: position.y, z: position.z }, 1000)
        .delay(index * 100)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => renderer.render(scene, camera))
        .start();
};



const resetAssembly = () => {
  if (meshesRef.current.length === 0) {
      console.log("No meshes found to reset.");
      return;
  }

  meshesRef.current.forEach(mesh => {
    if (mesh && mesh.originalPosition) { // Ensure mesh and originalPosition are not null
      new TWEEN.Tween(mesh.position)
        .to({ 
          x: mesh.originalPosition.x, 
          y: mesh.originalPosition.y, 
          z: mesh.originalPosition.z 
        }, 1000)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => renderer.render(scene, camera))
        .start();
    } else {
      console.error("Mesh or original position not properly set.");
    }
  });

  setIsAssembled(true); // Update state to reflect that parts are now reset to initial positions
  console.log("Assembly has been reset."); // Optional: log to console
};


  return (
    <div ref={viewerRef} style={{ width: '100%', height: '500px' }}>
      {assemblyData ? (
        <div>
          {loading ? (
            <ReactBootStrap.Spinner animation="border" />
          ) : (
            <div>
              <button onClick={toggleModel}>
                {isAssembled ? "Disassemble Parts" : "Assemble Parts"}
              </button>
              <button onClick={resetAssembly}>
                Reset Assembly
              </button>
            </div>
          )}
        </div>
      ) : (
        <div>No assembly data available.</div>
      )}
    </div>
  );
};

export default VisualizationComponent;