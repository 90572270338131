import {
    Button,
    Grid
} from '@material-ui/core';
import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from '../pages/styles/CommonStyles';
const ErrorPage = ({messageProps, statusProps, pageNameProps, dismissProps, updateHeaderStage}) => {
    const [message, setMessage] = useState("");
    const [status, setStatus] = useState(200);
    const [pageName, setPageName] = useState(200);

    useEffect(() => {
        setStatus(statusProps)
        setPageName(pageNameProps)
        setMessage(messageProps)
        console.log("Failed with status: " + statusProps);
    }, [])

    const toStart = () => {
        updateHeaderStage(2)
        dismissProps(true)
      }
    
    const classes = useStyles()

    return (
        <div style={{marginTop: "50px"}}>
            <Grid style={{paddingTop: "10%", paddingBottom: "5%"}} align="center">
                <h1>{status} Server Error</h1>
                <h4>On the <span style={{fontStyle: "italic"}}>{pageName}</span> page something went wrong. The following exception was raised:</h4>
            </Grid>
            <Grid className={classes.errorMessage} align="center">
                {message}
            </Grid>
            <Grid item xs={12} align="center" style={{marginTop:'5%'}}>
                <Button className={classes.retryButton} onClick={(evt) => dismissProps(false)} mt={2} color="primary" type="button" variant="contained">Retry</Button>
                <Button className={classes.toStartButton} onClick={(evt) => {toStart()}} mt={2} color="primary" type="button" variant="contained">To start</Button>
            </Grid>
        </div>
    )
};

export default ErrorPage;