import _ from 'lodash';
import axios from 'axios';
import { createContext, useState } from 'react'

const AuthContext = createContext()
export default AuthContext;

const AuthProvider = ({children}) => {
    let [authentificationToken, setAuthentificationToken] = useState(false)

    const updateAuthentificationToken = (value) => {
        setAuthentificationToken(value);
    };

    let getRefreshToken = async (code)=> {
        try{
            const response = await axios.get(`/api/get-refresh-token/?code=${encodeURIComponent(code)}`);
            console.log(response)
            const refreshTokenData = JSON.parse(response.data);
            const accessToken = refreshTokenData.access_token;
            const refreshToken = refreshTokenData.refresh_token;
            console.log('Access token:', accessToken)
            console.log('Refresh token:', refreshToken)
            const currentTime = new Date().getTime();
            localStorage.setItem('time', currentTime)
            localStorage.setItem('authTokens', accessToken)
            localStorage.setItem('refresh_token', refreshToken)
            if(refreshTokenData.error == 'invalid_grant'){
                console.log('invalid_grant')
                setAuthentificationToken(false);
            }else{
                setAuthentificationToken(true);
            }
        } catch (e){
            console.log('Something went wrong!', e);
        }
    }

    let contextData = {
        getRefreshToken:getRefreshToken,
        authentificationToken:authentificationToken,
        updateAuthentificationToken:updateAuthentificationToken
    }

    return(
        <AuthContext.Provider value={contextData} >
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}
