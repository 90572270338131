import { Button, Typography, Card, CardContent, Checkbox, FormControlLabel } from '@material-ui/core';
import { InfoOutlined, CheckCircleOutline, ArrowForward } from '@material-ui/icons';
import '../components/header.css'

import React, { useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

const UserGuidePage = ({onPageProps, updateHeaderStage}) => {
    const history = useHistory();
    const [isChecked, setIsChecked] = useState(false);

    const clearIndexedDB = async () => {
        
        const databases = ["assemblywise"];
        
        for (const dbName of databases) {
            var request = indexedDB.deleteDatabase(dbName);
            request.onerror = function(event) {
                console.error(`Error deleting database ${dbName}: `, event);
            };
            request.onsuccess = function(event) {
                console.log(`Database ${dbName} deleted successfully`);
            };
        }
    };

    useEffect(() => {
        localStorage.clear();
        clearIndexedDB();
    }, [])

    const userGuideSettings = () => {
        console.log("User Guide Settings");
        localStorage.setItem('next_to_part_studio', true)
        onPageProps(2)
        updateHeaderStage(2)
        history.push("/header")
    }

    const handleCheckboxChange = (event) => {
        console.log("handleCheckboxChange: ", event.target.checked);
        localStorage.setItem('user_guide_display', event.target.checked)
        setIsChecked(event.target.checked);
    };

    return (
        <div className="user-configuration-container">
            <Typography variant="h3" component="h1" align="center" gutterBottom>
                CAASP App User Documentation
            </Typography>
            
            <div className="user-docs-main-container">
                <Card variant="outlined" className="guide-section">
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            <InfoOutlined color="action" /> Introduction
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Welcome to the User Documentation for our application.
                        </Typography>
                    </CardContent>
                </Card>

                <Card variant="outlined" className="guide-section">
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            <CheckCircleOutline color="action" /> Getting Started
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The app consists of several steps, in which the user can input or change data. 
                            Follow the tips on each page to find out what data you can edit and how to do it.
                        </Typography>
                    </CardContent>
                </Card>

                <Card variant="outlined" className="guide-section">
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Features
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Explore the features and functionalities of our application.
                        </Typography>
                    </CardContent>
                </Card>
                {/* Repeat for other sections as needed */}
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                }
                label="If you prefer not to view this user guide every time you start the app, please check this box."
            />

            <Button
                startIcon={<ArrowForward />}
                onClick={userGuideSettings}
                color="primary"
                variant="contained"
                style={{ marginTop: '20px', marginLeft: '5px' }}
            >
                Next
            </Button>
        </div>
    );

};

export default UserGuidePage;