import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import './App.css';
import PrivateRoute from "./utils/PrivateRoute";
import React, {useContext, useState, useEffect} from 'react';
import {AuthProvider} from './context/AuthContext';

import CAASPHomePage from './pages/CAASPHomePage';
import Header from "./components/Header";
import ErrorPage from "./components/ErrorPage";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import PartTypeRecognitionPage from "./pages/PartTypeRecognitionPage";
import SubassemblyDivisionPage from "./pages/SubassemblyDivisionPage";
import IndividualConstraintsPage from "./pages/IndividualConstraintsPage";
import AssemblySequencePage from "./pages/AssemblySequencePage";
import UserGuidePage from "./pages/UserGuidePage";
import PartStudioSelectionPage from "./pages/PartStudioSelectionPage";
import BaseComponentSelectionPage from "./pages/BaseComponentSelectionPage";
import PreparationPage from "./pages/PreparationPage";
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Description: This page is the landing page. React router handle user route request and based on the request autometically redirect to specific page.
 * @component
 * return requested page
 * @return  {html}
*/

function App() {
  let [documentId, setDocumentId] = useState('')
  let [workspaceId, setWorkspaceId] = useState('')
  let [elementId, setElementId] = useState('')
  let [accessDenied, setAccessDenied] = useState(false)
  let [page, setPage] = useState(2)
  let [headerStageFunc, setHeaderStageFunc] = useState(() => () => {})
  let [userGuideDisplay, setUserGuideDisplay] = useState(false)
  let [showHeaderFunc, setShowHeaderFunc] = useState(() => () => {})
  let [showHeader, setShowHeader] = useState(false)
  
  let [showErrorPage, setShowErrorPage] = useState(false)
  let [errorMessage, setErrorMessage] = useState('')
  let [errorStatus, setErrorStatus] = useState(200)
  let [errorPageName, setErrorPageName] = useState('')
  // these two variables are used to pass the setters as props
  // default value is an empty function
  let [errorPageFunc, setErrorPageFunc] = useState(() => () => {})
  let [dismissErrorFunc, setDismissErrorFunc] = useState(() => () => {})

  const handleErrors = (message, status, pageName) => {
    setErrorMessage(message)
    setErrorStatus(status)
    setErrorPageName(pageName)
    setShowErrorPage(true)
  }

  const dismissErrors = (toStart) => {
    if (toStart){
      var savedData = JSON.parse(localStorage.getItem("savedData"))
      savedData[documentId] = {}
      localStorage.setItem('savedData', JSON.stringify(savedData))
      setPage(2)
      setShowHeader(false)
    }
    setShowErrorPage(false)
    setErrorMessage("")
    setErrorStatus(200)
  }

  const handleHeaderProps = (stage) => {
    setPage(stage)
  };

  useEffect(() => {
    // Extract parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');

    // Decode and extract the documentId, workspaceId, and elementId from the state parameter
    const decodedState = decodeURIComponent(state);
    const stateParams = new URLSearchParams(decodedState);
    const documentId = stateParams.get('documentId');
    setDocumentId(documentId)
    const workspaceId = stateParams.get('workspaceId');
    setWorkspaceId(workspaceId)
    const elementId = stateParams.get('elementId');
    setElementId(elementId)

    let user_guide_display = localStorage.getItem('user_guide_display');
    setUserGuideDisplay(user_guide_display)

    // set the setter functions to the proper ones
    setShowHeaderFunc((show) => (show) => setShowHeader(show));
    setErrorPageFunc(() => (message, stat, pageName, showError) => handleErrors(message, stat, pageName, showError))
    setDismissErrorFunc(() => (toStart) => dismissErrors(toStart))

    // manage local storage at the start of the app
    // clear the current document or create a new object if the data in null
    localStorage.setItem('partStudioTemporary', "")
    if (localStorage.getItem('savedData') === null){
      var savedData = {}
      savedData[documentId] = {}
      localStorage.setItem('savedData', JSON.stringify(savedData))
    } else {
      var savedData = JSON.parse(localStorage.getItem("savedData"))
      savedData[documentId] = {}
      localStorage.setItem('savedData', JSON.stringify(savedData))
    }

  }, [])

    return (
      <div className="App">
        <Router>
          <AuthProvider>
          <Switch>
               {/* display every page under the header and under the same route.
                  you can switch them by updating the page */}
              <Route path="/header">
                {page != 1 ?
                  <Header pageProps={page} onHeaderProps={handleHeaderProps} updateStageFunc={setHeaderStageFunc} showProps={showHeader} updateShowHeaderProps={showHeaderFunc} dismissErrorProps={dismissErrorFunc}/>
                :
                 <></>}
                {showErrorPage?
                <>
                  <ErrorPage messageProps={errorMessage} statusProps={errorStatus} pageNameProps={errorPageName} dismissProps={dismissErrorFunc} updateHeaderStage={headerStageFunc}/>
                </>
                :
                <>
                  {page == 1?
                    <UserGuidePage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} />
                  :
                  <></>
                  }
                  {page == 2?
                  <PartStudioSelectionPage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  {page == 3?
                    <SubassemblyDivisionPage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} updateShowHeaderProps={showHeaderFunc} redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  {page == 4?
                    <PartTypeRecognitionPage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  {page == 5?
                    <BaseComponentSelectionPage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  {page == 6?
                    <IndividualConstraintsPage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  {page == 7?
                    <AssemblySequencePage redirectErrorProps={errorPageFunc}/>
                  :
                  <></>
                  }
                  </>
                }
              </Route>
              <Route path="/preparation">
                <PreparationPage onPageProps={handleHeaderProps}/>
              </Route>
              <Route  path="/">
                {!userGuideDisplay ?
                  <UserGuidePage onPageProps={handleHeaderProps} updateHeaderStage={headerStageFunc} />
                  :
                  <Redirect to="/header"/>

                }
              </Route>
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    );
  }


export default App;
