import React, {useContext, useState, useEffect} from 'react'
import './header.css';
import {
    Button,
    Input,
    Grid
} from '@material-ui/core';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';
import { useIndexedDB } from './indexdb';
import { useDispatch } from 'react-redux';
import { uploadTriggered } from './actions';
import 'bootstrap/dist/css/bootstrap.min.css';

 

const Header = ({ pageProps, onHeaderProps, updateStageFunc, showProps, updateShowHeaderProps, dismissErrorProps }) => {
    const dispatch = useDispatch();

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    let [accessDenied, setAccessDenied] = useState(false)
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [defaultStage, setDefaultStage] = useState(2)
    let [startUpload, setStartUpload] = useState(false)
    const { saveToIndexedDB, loadFromIndexedDB } = useIndexedDB();
    
    
    useEffect(() => {


        if(code === null){
            setAccessDenied(true);
        }
        updateStageFunc((stage) => (stage) => setDefaultStage(stage));
        setDefaultStage(pageProps);
        onHeaderProps(pageProps);

        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)
          
    }, [])

    class Point extends React.Component {
        handleClick() {
            if (showProps || this.props.ownStage <= "3"){
                this.props._onClick(this.props.ownStage);
                onHeaderProps(this.props.ownStage);
                setDefaultStage(this.props.ownStage);
                dismissErrorProps(false)
            }
        }

        render() {
            return(
            <div
                className={
                `point ${ this.props.advanced }${ (this.props.currentStage === this.props.ownStage) ? " focused" : "" }${ (this.props.currentStage > this.props.ownStage) ? " active" : ""  }`
                }
                >
                <div className="point-area" onClick={ this.handleClick.bind(this) }></div>
                <div className="point-background"></div>
                <div className="point-dot"></div>
                <div className="point-line"></div>
                <>
                    {showProps || this.props.ownStage <= "3" ?
                        <p className="point-name" style={{top: this.props.ownStage % 2 === 0 ? '-27px' : '27px'}}>
                            { this.props.name }
                        </p>
                    :
                        <p className="point-name" style={{top: this.props.ownStage % 2 === 0 ? '-27px' : '27px', color: "grey"}}>
                            { this.props.name }
                        </p>
                    }
                </>
            </div>
            );
        }
    }

    class Panel extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
            stage: defaultStage
            }
        }
        handleClick = stage => {
            this.setState({
                stage: stage
            });
        };

        render() {
            return(
                    <>
                    <div className="navigation-pane">
                    <div className="path">
                        <Point
                        advanced=""
                        ownStage="1"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Start page"
                        />
                        <Point
                        advanced=""
                        ownStage="2"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Configuration page"
                        />
                        <Point
                        advanced={defaultStage == 3 ? "focused" : ""}
                        ownStage="3"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Subassembly division"
                        />
                        <Point
                        advanced=""
                        ownStage="4"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Part type recognition"
                        />
                        <Point
                        advanced=""
                        ownStage="5"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Base component selection"
                        />
                        <Point
                        advanced=""
                        ownStage="6"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Individual constraints"
                        />
                        <Point
                        advanced="last"
                        ownStage="7"
                        currentStage={ this.state.stage }
                        _onClick={ this.handleClick }
                        name="Assembly sequence"
                        />
                       
                    </div>
                    </div>
                    
                    { window.location.hostname == "localhost" ? 
                    <Grid item xs={12} align="center" style={{marginTop: '3.5%'}}>
                        {this.state.stage == 2 ?
                        <>
                            {startUpload ?
                            <>
                                <Button style={{marginLeft: '2%', marginRight: '5%', marginBottom:'5%', float:'right'}} onClick={(evt) => uploadProgress(this)} mt={2} color="primary" type="button" variant="contained">Upload</Button>
                                <Input id="progress-file" type="file" inputProps={{accept:".json,application/json"}} style={{marginBottom:'5%', float:'right'}} ></Input>
                            </>
                            :
                            <Button style={{marginRight: '5%', float: 'right'}} onClick={(evt) => setStartUpload(true)} mt={2} color="primary" type="button" variant="contained">Upload progress <UploadIcon></UploadIcon></Button>
                            }
                        </>
                        :
                        <></>
                        }
                        {this.state.stage > 2 ?
                        <Button style={{marginRight: '5%', marginBottom:'2%', float: 'right'}} onClick={(evt) => downloadProgress(this.state.stage)} mt={2} color="primary" type="button" variant="contained">Download progress <DownloadIcon></DownloadIcon></Button>
                        :
                        <></>
                        }
                    </Grid>
                    :
                    <></>
                    }
                    </>
            );
        }
    }

    async function parseJsonFile(upload) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.onload = event => resolve(JSON.parse(event.target.result))
          fileReader.onerror = error => reject(error)
          fileReader.readAsText(upload)
        })
      }

      async function uploadProgress(panel){
        var input = document.getElementById("progress-file");
        
        if(!input.files.length){
            throw "No file selected";
        }

        var upload = await parseJsonFile(input.files[0]);

        if(!("document" in upload && "workspace" in upload && "element" in upload && "savedData" in upload && "partStudio" in upload && "stage" in upload)){
            throw "Selected file is invalid progress save file";
        }
        if(documentId != upload['document']){
            throw "Uploaded file doesn't match selected document";
        }
        if(workspaceId != upload['workspace']){
            throw "Uploaded file doesn't match selected workspace";
        }
        if(elementId != upload['element']){
            throw "Uploaded file doesn't match selected element";
        }
       
        updateShowHeaderProps(false)

        let savedData = await loadFromIndexedDB('savedData');
        if (!savedData) {
        savedData = {};
        }

        // Update savedData with the new data for the current documentId
        savedData[documentId] = upload["savedData"];
        dispatch(uploadTriggered(true));
        // Save the updated savedData back to IndexedDB
        await saveToIndexedDB('savedData', savedData);

        // Save partStudio data to IndexedDB
        await saveToIndexedDB('partStudio', upload['partStudio']);

        onHeaderProps(upload['stage']);
        setDefaultStage(upload['stage']);
        panel.setState({
            stage: upload['stage']
        });
        if(upload['stage'] > 3){
            updateShowHeaderProps(true)
        }
        dismissErrorProps(false)
    }
    
    function downloadProgress(stage){
        var savedData = JSON.parse(localStorage.getItem("savedData"));
        var partStudio = localStorage.getItem("partStudio");

        var content = {
            "document": documentId,
            "workspace": workspaceId,
            "element": elementId,
            "stage": stage,
            "savedData": savedData[documentId],
            "partStudio": partStudio
        };

        const element = document.createElement("a");
        const jsonFile = new Blob([JSON.stringify(content,null,"\t")], {type: 'application/json'});
        element.href = URL.createObjectURL(jsonFile);
        element.download = "did-"+documentId+"-eid-"+elementId+"-stage-"+stage+"-progress.json";
        document.body.appendChild(element);
        element.click();
    }

    return(
        <div className="header"> 
            <nav className="navbar navbar-light bg-light">
            
            <Link to="/" className="navbar-brand">Home</Link> 
            <Link to="/about" className="nav-link">About</Link>
            <Link to="/contact" className="nav-link">Contact</Link>
            </nav>

      <Panel />
    </div>
  );
}

export default Header;