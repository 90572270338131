import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        width: "50%",
        tableLayout: "auto"
    },
    tableContainer: {
        overflowX: 'auto',
        borderRadius: 10,
        margin: '10px 10px 10px 0px',
        marginTop: '40px !important',
    },
    tableHeaderCell: {
        fontSize: "16px",
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.getContrastText(theme.palette.primary.dark),
        textAlign: 'center'
    },
    tableCell: {
        fontSize: "16px",
        textAlign: 'center'
    },
    avatar: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.getContrastText(theme.palette.primary.light)
    },
    name: {
        fontWeight: 'bold',
        color: theme.palette.secondary.dark
    },
    status: {
        fontWeight: 'bold',
        fontSize: '0.75rem',
        color: 'white',
        backgroundColor: 'grey',
        borderRadius: 8,
        padding: '3px 10px',
        display: 'inline-block'
    },
    vButton: {
        marginRight: '5px'
    },
    expandButton: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    expandAllButton: {
        fontSize: '14px',
    },
    iconHover: {
        '&:hover': {
            border: '2px solid green',
        }
    },
    tooltip: {
        fontSize: "1.2em",
    },
    paper: {
        textAlign: "center"
    },
    customCheckbox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '64px', /* Adjust width and height as needed */
        height: '40px', /* Adjust width and height as needed */
        borderRadius: '4px', /* Rounded corners, adjust as needed */
        cursor: 'pointer',
        color: '#3f51b5', // Default color
        '&$checked': {
          color: '#3f51b5', // Color when checked
        },
    },
    checked: {},
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(0.5), // Adjust margin as needed
        paddingTop: "10px",
        marginLeft: "50%",
    },
    formControl: {
        width: "20%",
        minWidth: '130px',
        maxWidth: '200px',
        marginRight: theme.spacing(1),
        textAlign: "center"
    },

    formControlConfig: {
        minWidth: '400px',
        marginBottom: theme.spacing(2),
    },

    centerButton: {
        marginTop: '10px',
        marginLeft: '5px',
    },

    errorMessage: {
        borderRadius: "3px",
        fontFamily: "monospace",
        padding: "0 3px",
        fontWeight: "bold",
        fontSize: "30px"
    },

    retryButton: {
        fontSize: "16px",
        marginRight: "25%"
    },

    toStartButton: {
        fontSize: "16px",
        marginLeft: "25%"
    },

    progressBarDiv: {
        width: "75%"
    },

    progressGrid: {
        marginTop: "10%"
    },

    progressBarMain: {
        height: "25px",
        fontSize: "16px",
        borderRadius: "20px"
    },

    questionCircle: {
    display: 'inline-block',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '3ex',
    height: '3ex',
    fontSize: '16px',
    lineHeight: '2.8ex',
    borderRadius: '2ex',
    color: 'grey',
    background: 'white',
    border: '1px solid',
    textDecoration: 'none',
    marginLeft: "15px",
},

questionButton: {
    marginTop: "5px"
},

tooltip: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    width: "50vw",
    transform: 'translate(-10%, -185%)',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px',
    borderRadius: '5px',
    opacity: '1',
    transition: 'opacity 0.3s',
},

tooltipSmall: {
    position: 'absolute',
    top: '82%',
    left: '75%',
    transform: 'translate(-10%, 185%)',
    backgroundColor: '#333',
    color: '#fff',
    padding: '5px',
    borderRadius: '5px',
    opacity: '1',
    transition: 'opacity 0.3s',
},

userTips: {
    position: "relative",
    width: "60%",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.43",
    letterSpacing: "0.01071em",
    backgroundColor: "rgba(200, 200, 200, 0.5)",
    border: "1px",
    borderRadius: "7px",
    padding: "7px 7px 7px 10px",
    marginTop: "2%",
    marginBottom: "2%",
    textAlign: "left",
    float: "left"
},

userTipsText: {
    marginBottom: "0px"
},

userInput: {
    position: "relative",
    width: "30%"
},

OKButton: {
    fontSize: "20px",
    marginLeft: "10px",
    textDecoration: "none",
    color: "green"
},

resetMainButton: {
    fontSize: "20px",
    marginRight: "10px",
    textDecoration: "none",
}

}),{index: 1});

export default useStyles;