import _ from 'lodash';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as ReactBootStrap from 'react-bootstrap';
import useStyles from './styles/CommonStyles';
import { useIndexedDB } from '../components/indexdb';
import '../components/header.css';
import VisualizationComponent from './VisualizationComponent';

const AssemblySequence = ({ redirectErrorProps }) => {
    const classes = useStyles();
    let [loading, setLoading] = useState(true)
    let [updating, setUpdating] = useState(true)
    let [editing, setEditing] = useState(true)
    let [finished, setFinished] = useState(false)
    let [assemblySequenceMatrix, setAssemblySequenceMatrix] = useState([])
    let [sequence, setSequence] = useState([])
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [productName, setProductName] = useState('')
    let [authToken, setAuthToken] = useState('')
    let [refreshToken, setRefreshToken] = useState('')
    let [startSocket, setStartSocket] = useState(false)
    let [partStudioTemporary, setPartStudioTemporary] = useState(false)
    let [partStudioDeleted, setPartStudioDeleted] = useState(false)
    let [partStudioId, setPartStudioId] = useState('')
    let [isTooltipVisible, setTooltipVisible] = useState(false)
    let [collapsed, setCollapsed] = useState({})
    

    let [editOn, setEditOn] = useState([])
    let [editOnGlobal, setEditOnGlobal] = useState(false)
    const [showVisualization, setShowVisualization] = useState(false);
    const isUploadTriggered = useSelector(state => state.upload.isUploadTriggered);
    const { saveToIndexedDB, loadFromIndexedDB, isDbReady } = useIndexedDB();

    const handleButtonClick = () => {
        
        setShowVisualization(!showVisualization);
    };

    useEffect(() => {
        if (isDbReady) {
            // Extract parameters from the URL
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const state = urlParams.get('state');

            const authTokens = localStorage.getItem('authTokens');
            setAuthToken(authTokens)
            setRefreshToken(localStorage.getItem("refresh_token"))

            // Decode and extract the documentId, workspaceId, and elementId from the state parameter
            const decodedState = decodeURIComponent(state);
            const stateParams = new URLSearchParams(decodedState);
            const documentId = stateParams.get('documentId');
            setDocumentId(documentId)
            const workspaceId = stateParams.get('workspaceId');
            setWorkspaceId(workspaceId)
            const elementId = stateParams.get('elementId');
            setElementId(elementId)

            const showAssemblySequence = async (isUploadTriggered, documentId) => {
                var currentDoc;

                if (isUploadTriggered) {
                    console.log('Upload triggered, loading data from IndexedDB.');
                    const savedDataFromIndexedDB = await loadFromIndexedDB('savedData');
                    currentDoc = savedDataFromIndexedDB ? savedDataFromIndexedDB[documentId] : null;
                    requestProcess(documentId, "token");
                }

                else {
                    console.log('Loading data from localStorage.');
                    const savedDataFromLocalStorage = JSON.parse(localStorage.getItem("savedData")) || {};
                    currentDoc = savedDataFromLocalStorage[documentId];
                    requestProcess(documentId, "token");
                }

                try {
                    await axios.patch(`/api/assembly-sequence-details/`, JSON.stringify(currentDoc),
                        {
                            params: {
                                'documentId': documentId,
                                'workspaceId': workspaceId,
                                'elementId': elementId,
                                'authTokens': authTokens
                            }
                        }).then(response => {
                        });
                } catch (error) {
                    var message = error.response.data["message"]
                    if (message == undefined) {
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, error.response.status, "Assembly sequence")
                    setLoading(false)
                }
            }

            showAssemblySequence(isUploadTriggered, documentId);

        }
    }, [isDbReady, isUploadTriggered, documentId]);

    
    

    const requestProcess = (docId, token) => {
        try {
            if (window.location.protocol == 'http:') {
                var url = `ws://${window.location.host}/ws/assembly-sequence/${docId}/`
            } else {
                var url = `wss://${window.location.host}/ws/assembly-sequence/${docId}/`
            }
            const websocket = new WebSocket(
                url, ["Token", token]
            );
            setStartSocket(true)

            websocket.onmessage = async function (e) {
                let data = JSON.parse(e.data);
                if (data.type === 'connection_established' || data.type === 'progress') {

                } else if (data.type === 'completed') {
                    try {
                        formFlags(data.response["response"].length);
                        if(!isUploadTriggered){
                        var  savedData = JSON.parse(localStorage.getItem("savedData"))
                        var currentDoc = data.response["savedData"];
                        
                        savedData[docId] = currentDoc; // Update savedData with the current document
                        localStorage.setItem("savedData", JSON.stringify(savedData)); // Attempt to save updated savedData back to localStorage
                        }
                        else{
                            const savedData = await loadFromIndexedDB('savedData');
                            var currentDoc = data.response["savedData"]
                            savedData[docId] = currentDoc
                            saveToIndexedDB("savedData",savedData)
                        }
                    } catch (error) {
                        console.error("Error updating localStorage, attempting to use IndexedDB as fallback:", error);
                        // IndexedDB fallback logic here
                        saveToIndexedDB('savedData', savedData).then(() => {
                            console.log("Saved to IndexedDB as fallback");
                        }).catch(dbError => {
                            console.error("Error saving to IndexedDB:", dbError);
                        });
                    }

                    setCollapsed(formCollapsed(data.response["response"]))
                    setAssemblySequenceMatrix(data.response["response"])
                    setSequence(data.response["sequence"])
                    console.log(data.response["sequence"])
                    setProductName(data.response["productName"])
                    const partStudioTemporary =  loadFromIndexedDB("partStudioTemporary") !== "";
                    setPartStudioTemporary(data.response["partStudioTemporary"] && (localStorage.getItem("partStudioTemporary") != ""));
                    setPartStudioDeleted(data.response["partStudioTemporary"] && (localStorage.getItem("partStudioTemporary") == ""));
                    setPartStudioId(data.response["partStudioId"])

                    setLoading(false);
                    setTimeout(() => {
                        collapseRows("");
                        collapseRows("")
                    }, 20)
                    
                    // remove event listener from a tooltip
                    var delete_btn = document.getElementById("deleteInfo")
                    var btn = document.getElementById("tipp")
                    if (delete_btn != undefined) {
                        function clickable() { console.log("click") }
                        delete_btn.addEventListener('click', clickable)
                        delete_btn.removeEventListener('click', clickable)
                        btn.addEventListener('click', clickable)
                        btn.removeEventListener('click', clickable)
                    }
                    websocket.close()
                } else if (data.type === 'error') {
                    console.log(data.message)
                    var message = data.message
                    if (message == undefined) {
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, 500, "Assembly sequence")
                    setLoading(false)
                    websocket.close()
                }
            };
        } catch (error) {
            console.log(error)
            var message = error.message
            redirectErrorProps(message, error.status, "Assembly sequence")
            setLoading(false)
        }
    };

    function formFlags(num) {
        var res = []
        for (let i = 0; i < num; i++) {
            res.push(false)
        }
        setEditOn(res)
        setEditOnGlobal(false)
    }

    function formCollapsed(data) {
        var res = {}
        res[""] = "visible"
        data.forEach(
            function (node, index) {
                if (node["Part Name"] == "Group") {
                    res[node["Assembly Group"]] = "collapsed"
                }
            }
        )
        return res
    }

    const playAssemblyGroupMovement = async (item, partNames) => {
        var btn = document.getElementById(item)
        btn.innerHTML = "◼"
        try {
            await axios({
                method: 'get',
                url: `/api/play-assembly-group-sequence/`,
                params: {
                    'part_names': partNames,
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'authTokens': authToken,
                    'refreshTokens': refreshToken,
                    'partStudioId': localStorage.getItem("partStudio")
                }
            }).then(response => { });
            btn.innerHTML = "▶"
        } catch (e) {
            alert("Failed to play the animation. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            btn.innerHTML = "▶"
        }
    }

    function startDownload(path, name) {
        fetch(path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    name + '.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const downloadSequenceTable = async () => {
        try {
            await axios.patch(`/api/get-assembly-sequence-table/`, JSON.stringify(assemblySequenceMatrix),
                {
                    params: {
                        'documentId': documentId,
                        'productName': productName,
                        'authTokens': authToken,
                        'refreshTokens': refreshToken,
                    }
                }).then(response => { startDownload("media/tables/" + documentId + "_sequence.xlsx", response.data["filename"]) });
        } catch (e) {
            alert("Failed to export the table. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
        }
    }

    const deletePartStudio = async () => {
        try {
            await axios({
                method: 'get',
                url: `/api/delete-part-studio/`,
                params: {
                    'refreshTokens': refreshToken,
                    'authTokens': authToken,
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': partStudioId
                }
            }).then(response => {
                setPartStudioTemporary(false)
                setPartStudioDeleted(true)
                localStorage.setItem("partStudioTemporary", "")
            });
        } catch (e) {
            alert("Failed to delete part studio. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
        }
    }

    function collapseRows(group, all = 0) {
        var target = document.getElementsByName("Row_" + group);
        var expandButton = document.getElementById("Collapse_" + group);
        var container = document.getElementById("ValueCollapse_" + group);
        if (container == null) {
            return
        }
        if (container.title == "-") {
            expandButton.innerHTML = "↪";
            collapsed[group] = "collapsed"
            container.title = "+";
            target.forEach(
                function (node, index) {
                    node.style.visibility = "collapse"
                    if (node.dataset.isgroup == "true") {
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "-") {
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        } else {
            expandButton.innerHTML = "⤵";
            container.title = "-";
            collapsed[group] = "visible"
            target.forEach(
                function (node, index) {
                    node.style.visibility = "visible"
                    if (all && node.dataset.isgroup == "true") {
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "+") {
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        }
    }

    function setEdit(index, value) {
        setEditOnGlobal(value)
        editOn[index] = value
    }

    function getPartByItem(parts, item) {
        if (!item.length || !item[0]) {
            return parts
        }
        return getPartByItem(parts[0][item[0] - 1], item.slice(1, item.length))
    }

    function setPartByItem(parts, updatedPart, item) {
        if (!item.length) {
            parts = updatedPart
            return parts
        }
        parts[0][item[0] - 1] = setPartByItem(parts[0][item[0] - 1], updatedPart, item.slice(1, item.length))
        return parts
    }

    function moveParts(parts, part, newIndex) {
        // move a part to its new index and all other parts up/down to fill empty space.
        // a part can only be moved in the subassembly it belongs to.
        // a subassembly can also be moved with the same rules.

        // it's easier to move a part in a nested list, though it has to be converted to a "response" type "linear" list again,
        // there is a recursive function for that (similar to one in the consumers.py)
        var item = part["Item"]
        if (typeof item != "string") {
            item = String(item)
        }
        var assembly = getPartByItem(parts, part["Assembly Parent"].split('.'))
        var oldIndex = part["Sequence Number"] - 1
        var p = assembly[0][oldIndex]

        if (newIndex > oldIndex) {
            for (let i = oldIndex; i < newIndex; i++) {
                assembly[0][i] = assembly[0][i + 1]
            }
        } else {
            for (let i = oldIndex; i > newIndex; i--) {
                assembly[0][i] = assembly[0][i - 1]
            }
        }
        assembly[0][newIndex] = p
        parts = setPartByItem(parts, assembly, part["Assembly Parent"].split('.'))

        return parts
    }

    function deleteSubassembly(parts, part){
        var parent = part["Assembly Parent"].split('.')
        var assembly = getPartByItem(parts, parent)
        var list = assembly[0]
        list.splice(part["Sequence Number"] - 1, 1)
        if (!list){
            list = []
        }
        assembly[0] = list
        setPartByItem(parts, assembly, parent)
        return parts
    }

    function g_str(g) {
        return g.join('.')
    }

    function iteratePartsRecurcive(parts, g, response) {
        var g_ = 0
        parts.forEach(
            function (part, index) {
                g_ += 1
                var partId = part[0]
                if (Array.isArray(partId)) {
                    g.push(g_)
                    let parts = []
                    partId.forEach(
                        function(node, index){
                            if (typeof node[0] == "string"){
                                parts.push(node[0])
                            }
                        }
                    )
                    response.push({
                        "Assembly Group": g_str(g),
                        "Assembly Parent": g_str(g.slice(0, -1)),
                        "Sequence Number": index + 1,
                        "MaxValue": parts.length,
                        "Part Number": part[1],
                        "Part Name": "Group",
                        "Direction": part[2],
                        "Thumbnail": part[3],
                        "Collapse": g_str(g.slice(0, -1)),
                        "Level": g.length - 1,
                        "Parts": parts,
                        "Item": g_str(g.slice(0, -1)) ? g_str(g.slice(0, -1)) + '.' + (index + 1) : index + 1
                    })
                    response = iteratePartsRecurcive(partId, g, response)
                    g.pop()
                } else if (typeof partId == "string") {
                    var partName = part[1]
                    response.push({
                        "Assembly Group": g_str(g),
                        "Assembly Parent": g_str(g),
                        "Sequence Number": index + 1,
                        "MaxValue": parts.length,
                        "Part Number": partId,
                        "Part Name": partName,
                        "Direction": part[2],
                        "Thumbnail": part[3],
                        "Collapse": g_str(g),
                        "Level": g.length,
                        "Item": g_str(g) ? g_str(g) + '.' + (index + 1) : index + 1
                    })
                }
            }
        )

        return response
    }

    function applyMove(item, index, reset=false) {
        // if reset is set as true, a subassembly will be moved to the main (root) assembly
        if (typeof item != "string") {
            item = String(item)
        }
        var matrix = [...assemblySequenceMatrix]
        var seq = [...sequence]
        var part = matrix[index]

        if (!reset){
            var newIndex = document.getElementById("input_" + item).value

            if (!newIndex || newIndex == part["Sequence Number"]) {
                setEdit(index, false)
                return;
            }
            setEditing(false)
            seq = moveParts(seq, part, newIndex - 1)
        } else{
            if (part["Assembly Parent"] == ""){
                setEdit(index, false)
                return
            }
            var subassembly = getPartByItem(seq, item.split('.'))
            seq = deleteSubassembly(seq, part)
            seq[0].push(subassembly)
        }

        setSequence(seq)
        matrix = iteratePartsRecurcive(seq[0], [], [])
        setEdit(index, false)
        setAssemblySequenceMatrix(matrix)

        let btn = document.getElementById("Collapse_")
        setTimeout(() => { setCollapsed(formCollapsed(matrix)) }, 50)
        setTimeout(() => setEditing(true), 50)
        setTimeout(() => { btn.click(); btn.click() }, 50)

        for (const [item, c] of Object.entries(collapsed)) {
            if (c != "collapsed" && item) {
                setTimeout(() => { collapseRows(item) }, 50)
            }
        }

    }

    const updateSequence = async() => {
        setUpdating(false)
        var savedData = JSON.parse(localStorage.getItem("savedData")) || {};
        if (savedData && savedData[documentId]) {
            savedData = savedData[documentId];
        }

        else {
            // Fallback to loading from IndexedDB if not found in localStorage
            try {
                const dataFromIndexedDB = await loadFromIndexedDB('savedData');
                if (dataFromIndexedDB && dataFromIndexedDB[documentId]) {
                    savedData = dataFromIndexedDB[documentId];
                } else {
                    throw new Error('No saved data found for this documentId in both IndexedDB and localStorage.');
                }
            } catch (error) {
                console.error("Failed to load from IndexedDB:", error);
                setUpdating(true);
                return; // Exit the function if no data found in both storages
            }
        }
        try{
            await axios.post('/api/assembly-sequence-details/', JSON.stringify({"savedData": savedData, "sequence": sequence}),
            {
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'authTokens': authToken,
                }
            }).then((response) => {
                var savedData = JSON.parse(localStorage.getItem("savedData"))
                var currentDoc = response.data["savedData"]
                savedData[documentId] = currentDoc
                localStorage.setItem("savedData", JSON.stringify(savedData))
                console.log("successfully updated")
                setUpdating(true)});
        } catch (e){
            alert("Failed to update the assembly sequence. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            setUpdating(true)
        }
    }

    
    return (
        <div className="part-type-recognition-main" style={{ marginLeft: '1%', width: '98%', marginTop: '6%' }}>
            {loading ?
                <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '50%', marginTop: '25%' }} />
                :
                <div className="part-type-recognition-table" style={{ marginLeft: '1%', width: '98%' }}>
                    <TableContainer className="matrixdata" style={{ marginTop: '6%' }} spacing={1} component={Paper}>
                        <Button color="default" type="button" onClick={(evt) => downloadSequenceTable()} variant="contained">Export sequence as .xlsx</Button>
                        <Table>
                            <TableHead>
                                <TableRow className="matrixhead" xs={12}>
                                    <TableCell className={classes.tableHeaderCell}>
                                        <div id="ValueCollapse_" title="-"></div>
                                        <a id="Collapse_" onClick={(evt) => collapseRows("")} type='button' style={{ paddingRight: '15px' }}>⤵</a>
                                        <span>Item</span>
                                    </TableCell>
                                    {/* <TableCell className={classes.tableHeaderCell}>Part Number</TableCell> */}
                                    <TableCell className={classes.tableHeaderCell}>Part Name</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Assembly Direction</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>
                                        <span>Thumbnail</span>
                                        <a id="Collapse_All" onClick={(evt) => collapseRows("", 1)} type='button' style={{ paddingLeft: '15px' }} title="Hide/Expand all">⏏</a>
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}><span>Edit</span></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(assemblySequenceMatrix, (r, v, k) =>
                                        <>
                                            {editing ?
                                                <TableRow xs={12} name={"Row_" + r["Collapse"]} data-isgroup={r["Part Name"] == 'Group'} data-buttonid={"Collapse_" + r["Assembly Group"]} style={{ visibility: collapsed[r["Collapse"]] }}>
                                                    <TableCell className={classes.tableCell}>
                                                        {r["Part Name"] == 'Group' ?
                                                            <span>
                                                                <div id={"ValueCollapse_" + r["Assembly Group"]} title="-"></div>
                                                                <a id={"Collapse_" + r["Assembly Group"]} onClick={(evt) => collapseRows(r["Assembly Group"])} type='button' style={{ paddingRight: '15px' }}>⤵</a>
                                                                {r["Item"]}
                                                                <a id={r["Item"]} onClick={(evt) => playAssemblyGroupMovement(r["Item"], r["Parts"])} type="button" title="play" style={{ paddingLeft: '15px' }}>▶</a>
                                                            </span>
                                                            :
                                                            <span style={{ paddingLeft: '30px' }}>{r["Item"]}</span>
                                                        }
                                                    </TableCell>
                                                    {/* <TableCell>{r["Part Number"]}</TableCell> */}
                                                    <TableCell className={classes.tableCell} style={{ paddingLeft: "9%" }}>
                                                        <div style={{ paddingLeft: 16 + 15 * r["Level"] + "px", textAlign: "left" }}>
                                                            {r["Part Name"] == 'Group' ?
                                                                r["Part Number"]
                                                                :
                                                                r["Part Name"]
                                                            }
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>{r["Direction"]}</TableCell>
                                                    <TableCell className={classes.tableCell}><img src={"/api/get-private-content?url=" + encodeURIComponent(r["Thumbnail"]) + "&authTokens=" + encodeURIComponent(authToken)} /></TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        <>
                                                            {!editOnGlobal && !editOn[v] ?
                                                                <>
                                                                    {r["Part Name"] == "Group" && r["Assembly Parent"] != "" ?
                                                                        <a title="Reset the subassembly to root" className={classes.resetMainButton} role="button" onClick={() => applyMove(r["Item"], v, true)}>↩</a>
                                                                    :
                                                                    <></>
                                                                    }
                                                                <Button color="secondary" type="button" variant='contained' onClick={() => setEdit(v, true)}
                                                                        style={{marginLeft: r["Part Name"] == "Group" && r["Assembly Parent"] != "" ? "" : "31px"}}>Move</Button>
                                                                </>
                                                                : editOnGlobal && !editOn[v] ?
                                                                    <Button color="default" type="button" variant='contained'>Move</Button>
                                                                    :
                                                                    <>{r["Assembly Parent"] ? r["Assembly Parent"] + "." : ""}
                                                                        <input id={"input_" + r["Item"]} className={classes.userInput} type="number" min="1" max={r["MaxValue"]} defaultValue={r["Sequence Number"]} />
                                                                        <a className={classes.OKButton} role="button" onClick={() => applyMove(r["Item"], v)}>✔</a>
                                                                    </>
                                                            }
                                                        </>
                                                    </TableCell>
                                                </TableRow>
                                                :
                                                <></>
                                            }</>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {updating && !finished ?
                        <Grid item xs={12} align="center" style={{ marginBottom: '5px' }}>
                            <div className={classes.userTips}>
                                <p className={classes.userTipsText}>Please adjust the final sequence if necessary and save it.</p>
                            </div>
                            <Grid style={{ float: "left", marginTop: "2.5%" }}>
                                <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                                    <span className={classes.questionCircle}>?</span>
                                    {isTooltipVisible && (
                                        <div className={classes.tooltip} style={{ top: "95%" }}>
                                            The AssemblyWise sequence might not match the optimal sequence 100%. That's why the user can easily adjust the sequence.
                                        </div>)}
                                </a>
                            </Grid>
                            <Button style={{ marginRight: '5%', marginTop: '2%', float: 'right' }} onClick={(evt) => setFinished(true)} mt={2} color="primary" type="button" variant="contained">Finish</Button>
                            <Button style={{ marginRight: '5%', marginTop: '2%', float: 'right' }} onClick={() => {updateSequence()}} mt={2} color="primary" type="button" variant="contained">Update</Button>
                            <button onClick={handleButtonClick} className="btn-primary">
                                VISUALIZE SEQUENCE
                                </button>
                                {showVisualization && (
                                <VisualizationComponent
                                    documentId={documentId}
                                    workspaceId={workspaceId}
                                    elementId={elementId}
                                    authToken={authToken}
                                    sequence={sequence}
                                    
                                />
                                )}
                        </Grid>
                        : !finished ?
                            <ReactBootStrap.Spinner animation="border" style={{ marginRight: '5%', marginTop: '2%', float: 'right' }} />
                        : finished && partStudioTemporary && !partStudioDeleted ?
                            <Grid style={{ float: "right", marginRight: "2%", marginTop: "15px" }}>
                                <Button color="primary" type="button" onClick={() => deletePartStudio()} variant="contained">Delete part studio</Button>
                                <a id="deleteInfo" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                                    <span className={classes.questionCircle}>?</span>
                                    {isTooltipVisible && (
                                        <div
                                            className={classes.tooltip}>
                                            The currently selected part studio
                                            was temporarily created for this document. If the sequence was generated successfully and
                                            you want to delete it, you can do it by clicking the "Delete" button
                                        </div>)}
                                </a>
                            </Grid>
                        : finished && partStudioDeleted ?
                            <>
                                <div className={classes.userTips} style={{ float: "left", width: "30%" }}>
                                    <p className={classes.userTipsText}>Thank you for using our app!</p>
                                </div>
                            <Grid id="deleteInfo" style={{ float: "right", marginRight: "2%", marginTop: "15px" }}>

                            <a id="deleteInfo" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                            <span className={classes.questionCircle}>?</span>
                            {isTooltipVisible && (
                            <div
                                className={classes.tooltipSmall}>
                                Successfully deleted part studio
                            </div>)}
                        </a>
                        </Grid>
                        </>
                    :
                    <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Thank you for using our app!</p>
                    </div>
                    }
                </div>
            }
            
        </div>
    )
};

export default AssemblySequence;