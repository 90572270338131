import _ from 'lodash';
import axios from 'axios';
import React, { useState, useEffect} from 'react';
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import * as ReactBootStrap from 'react-bootstrap';
import useStyles from './styles/CommonStyles';
import { useIndexedDB } from '../components/indexdb';
import { useSelector } from 'react-redux';

const IndividualConstraints = ({onPageProps, updateHeaderStage, redirectErrorProps}) => {
    let [loading, setLoading] = useState(true)
    let [updating, setUpdating] = useState(true)
    let [updated, setUpdated] = useState(false)
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [authToken, setAuthToken] = useState('')

    let [constraintsMatrix, setConstraintsMatrix] = useState([])
    let [constraintsList, setConstraintsList] = useState({})
    let [startSocket, setStartSocket] = useState(false)
    let [isTooltipVisible, setTooltipVisible] = useState(false)
    const isUploadTriggered = useSelector(state => state.upload.isUploadTriggered);
    const { saveToIndexedDB, loadFromIndexedDB,isDbReady } = useIndexedDB();

    useEffect(() => {
        if (isDbReady){
        // Extract parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const authTokens = localStorage.getItem('authTokens');
        setAuthToken(authTokens)
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)

        const getIndividualConstraints = async (isUploadTriggered,documentId) => {
            var currentDoc;
            
            if (isUploadTriggered) {
                console.log('Upload triggered, loading base component data from IndexedDB.');
                const savedDataFromIndexedDB = await loadFromIndexedDB('savedData');
              
                currentDoc = savedDataFromIndexedDB ? savedDataFromIndexedDB[documentId] : null;
                console.log("individual constraints Process in Progress !!!")
                requestProcess(documentId, "token");
                console.log("individual constraints Process complete!!!")
            }
            
            else {
                console.log('individual constraints  data from localStorage.');
                const savedDataFromLocalStorage = JSON.parse(localStorage.getItem("savedData")) || {};
                currentDoc = savedDataFromLocalStorage[documentId];
                requestProcess(documentId, "token");
            }

            try{
                await axios.patch('/api/individual-constraints/', JSON.stringify(currentDoc),
                {   headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        'documentId': documentId,
                        'workspaceId': workspaceId,
                        'elementId': elementId,
                        'authTokens': authTokens
                    }
                })
                .then((response) => {
                })
            }catch(error){
                console.log(error)
                if (error.response === undefined){
                    var message = error.message
                    var status = error.status
                } else{
                    var message = error.response.data["message"]
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    var status = error.response.status
                }
                redirectErrorProps(message, status, "Individual constraints")
                setLoading(false)
            }
        }
        getIndividualConstraints(isUploadTriggered,documentId);

    };

    }, [isDbReady,isUploadTriggered,documentId])

    const requestProcess = (docId, token) => {
        try{
            if (window.location.protocol == 'http:'){
                var url = `ws://${window.location.host}/ws/individual-constraints/${docId}/`
            } else{
                var url = `wss://${window.location.host}/ws/individual-constraints/${docId}/`
            }
            const websocket = new WebSocket(
            url, ["Token", token]
            );
            console.log('WebSocket connection initiated');
            websocket.onopen = () => {
                console.log('WebSocket connection established');
                setStartSocket(true)
            };
            
            websocket.onmessage = async function (e) {
                let data = JSON.parse(e.data);
                await handleWebSocketMessage(data);
                };

            websocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
        
            websocket.onclose = () => {
                console.log('WebSocket connection closed');
            };

            const handleWebSocketMessage = async (data) => {
                if (data.type === 'connection_established' || data.type === 'progress') {
                    
                } else if (data.type === 'completed') {
                    
                        try {
                            if(!isUploadTriggered){
                            var savedData = JSON.parse(localStorage.getItem("savedData"))
                            var currentDoc = data.response["savedData"]
                            savedData[docId] = currentDoc
                            localStorage.setItem("savedData", JSON.stringify(savedData))
                            }
                            else{
                                const savedData = await loadFromIndexedDB('savedData');
                                var currentDoc = data.response["savedData"]
                                savedData[docId] = currentDoc
                                saveToIndexedDB("savedData",savedData)
                                
                            }
                    }
                
                catch (error) {
                    console.error("Error updating localStorage, attempting to use IndexedDB as fallback:", error);
                    // IndexedDB fallback logic here
                    saveToIndexedDB('savedData', savedData).then(() => {
                        console.log("Saved to IndexedDB as fallback");
                    }).catch(dbError => {
                        console.error("Error saving to IndexedDB:", dbError);
                    });
                }
                    setConstraintsMatrix(data.response["response"])
                    setConstraintsList(formConstraintsList(data.response["response"]))
                    setLoading(false);
                    collapseRows("")
                    collapseRows("")

                    // remove event listener from a tooltip
                    var tip = document.getElementById("tipp")
                    if (tip != undefined){
                        function clickable(){console.log("click")}
                        tip.addEventListener('click', clickable)
                        tip.removeEventListener('click', clickable)
                    }
                    websocket.close()
                } else if (data.type === 'error') {
                    console.log(data.message)
                    var message = data.message
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, 500, "Indvidual constraints")
                    setLoading(false)
                    //websocket.close()
                }
            }
        
        } catch(error){
            console.log(error)
            var message = error.message
            redirectErrorProps(message, error.status, "Indvidual constraints")
            setLoading(false)
        }
    
    };

    const classes = useStyles();

    function formConstraintsList(data){
        data.forEach(
            function(node, item){
                constraintsList[node["Part Number"]] = node["Constraint"]
            }
        )
        return constraintsList
    }

    function collapseRows(group, all=0){
        var target = document.getElementsByName("Row_" + group);
        var expandButton = document.getElementById("Collapse_" + group);
        var container = document.getElementById("ValueCollapse_" + group);
        if (container.title == "-"){
            expandButton.innerHTML = "↪";
            container.title = "+";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "collapse"
                    if (node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "-"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        } else {
            expandButton.innerHTML = "⤵";
            container.title = "-";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "visible"
                    if (all && node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "+"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        }
    }

    function selectIndividualConstraints(partId, assemblyGroup, isGroup){
        // the code below can be used if you want to automatically include all the parts from a subassembly
        // to individual constraints after clicking the group

        constraintsList[partId] = !constraintsList[partId]
        // if (isGroup) {
        //     var part = document.getElementById(partId + "_checkbox")
        //     var parts = document.getElementsByName("checkbox_" + assemblyGroup)
        //     parts.forEach(
        //         function(node, index) {
        //             if (node.checked != part.checked){
        //                 node.click()
        //             }
        //         });
        // }
    }

    function nextStep(stage){
        updateHeaderStage(stage);
        onPageProps(stage);
    }

    function clearConstraints(){
        constraintsMatrix.forEach(
            function(node, index){
                constraintsList[node["Part Number"]] = false
                constraintsMatrix[index]["Constraint"] = false
            }
        )
        updateConstraints("true")
    }

    const updateConstraints = async (clear = "false") => {
        setUpdating(false)
        //var savedData = JSON.parse(localStorage.getItem("savedData"))[documentId]
        var savedData;

        if (isUploadTriggered) {
            console.log('update triggered, getting individual data from IndexedDB.');
            const savedDataFromIndexedDB = await loadFromIndexedDB('savedData');
            console.log("individual constraints index db Process in Progress !!!")
            savedData = savedDataFromIndexedDB ? savedDataFromIndexedDB[documentId] : null;
           
            await saveToIndexedDB("savedData",savedData);
            console.log("individual constraints Index db Process complete!!!")
        }
        else {
            console.log('update triggered ,getting individual constraints data from localStorage.');
            const savedDataFromLocalStorage = JSON.parse(localStorage.getItem("savedData"));
            savedData = savedDataFromLocalStorage[documentId];
            console.log("individual constraints Process complete!!!")
        }
        try{
            await axios.post('/api/individual-constraints/', JSON.stringify(savedData),
            {   headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'authTokens': authToken,
                    'constraintsList': [constraintsList],
                    'clear': clear
                }
            }).then(async(response) => {
                if(!isUploadTriggered){

                    var savedData = JSON.parse(localStorage.getItem("savedData"))
                    var currentDoc = response.data["savedData"]
                    savedData[documentId] = currentDoc
                    localStorage.setItem("savedData", JSON.stringify(savedData))
                    }
                    else{
                        const savedDataFromIndexedDB = await loadFromIndexedDB('savedData');
                        const currentDoc = response.data.savedData;
                            // Assuming 'savedData' in IndexedDB is an object that includes documentId as a key
                            if (savedDataFromIndexedDB) {
                                savedDataFromIndexedDB[documentId] = currentDoc;
                                await saveToIndexedDB('savedData', savedDataFromIndexedDB);
                            }
    
                    }
                
                constraintsMatrix.forEach(
                    function(node, index){
                        if (constraintsList[node["Part Number"]]){
                            constraintsList[node["Part Number"]] = false
                            constraintsMatrix[index]["Constraint"] = true
                        }
                    }
                )
                console.log("successfully updated")
            setUpdating(true)});
        } catch (e){
            alert("Failed to update individual constraints. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            setUpdating(true)
        }
    }
    
    return (
        <div className="subassembly-structure-main" style={{ marginBottom: '5px', marginRight: '5px', marginTop:'5px', padding: '3px 10px 1px 10px'}}>
        {loading ?
            <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '50%',marginTop:'25%'}} />
            :
            <div className="subassembly-structure-table" style={{ width: '100%'}}>
                <TableContainer className="matrixdata" style={{marginTop:'6%'}} spacing={1} component={Paper}>
                    <Button style={{marginRight: '5px', marginBottom: '2px', float: 'right'}} onClick={(evt) => {clearConstraints()}} mt={2} color="secondary" type="button" variant="contained">Clear constraints</Button>
                    <Table>
                        <TableHead>
                            <TableRow className="matrixhead" xs={12}>
                                <TableCell className={classes.tableHeaderCell}>
                                    <div id="ValueCollapse_" title="-"></div>
                                    <a id="Collapse_" onClick={(evt) => collapseRows("")} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                    <span>Item</span>
                                    </TableCell>
                                {/* <TableCell className={classes.tableHeaderCell}>Part Number</TableCell> */}
                                <TableCell className={classes.tableHeaderCell}>Part Name</TableCell>
                                <TableCell className={classes.tableHeaderCell}>
                                    <span>Thumbnail</span>
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell}>
                                        <span>Individual constraint</span>
                                        <a id="Collapse_All" onClick={(evt) => collapseRows("", 1)} type='button' style={{paddingLeft: '15px'}} title="Hide/Expand all">⏏</a>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                _.map(constraintsMatrix, (r,v,k) =>
                                        <TableRow xs={12} name={"Row_" + r["Collapse"]} data-isgroup={r["Part Name"] == 'Group'} data-buttonid={"Collapse_" + r["Assembly Group"]}>
                                            <TableCell className={classes.tableCell}>
                                                {r["Part Name"] == 'Group' ?
                                                    <span>
                                                        <div id={"ValueCollapse_" + r["Assembly Group"]} title="-"></div>
                                                        <a id={"Collapse_" + r["Assembly Group"]} onClick={(evt) => collapseRows(r["Assembly Group"])} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                                        {r["Item"]} 
                                                    </span>
                                                :
                                                    <span style={{paddingLeft: '30px'}}>{r["Item"]}</span>
                                                }
                                            </TableCell>
                                            {/* <TableCell>{r["Part Number"]}</TableCell> */}
                                            <TableCell className={classes.tableCell} style={{paddingLeft: "8%"}}>
                                                <div style={{paddingLeft: 16 + 15 * r["Level"] + "px", textAlign: "left"}}>
                                                    {r["Part Name"] == 'Group' ?
                                                        r["Part Number"]
                                                    :
                                                        r["Part Name"]
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}><img src={"/api/get-private-content?url=" + encodeURIComponent(r["Thumbnail"])+"&authTokens=" +encodeURIComponent(authToken)} /></TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {updating ?
                                                <>
                                                {r["Constraint"] ?
                                                <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkbox_" + r["Collapse"]} classes={{
                                                        root: classes.customCheckbox
                                                    }} onChange={(evt) => selectIndividualConstraints(r["Part Number"], r["Assembly Group"], r["Part Name"] == "Group")} defaultChecked disabled/>
                                                :
                                                <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkbox_" + r["Collapse"]} classes={{
                                                        root: classes.customCheckbox
                                                    }} onChange={(evt) => selectIndividualConstraints(r["Part Number"], r["Assembly Group"], r["Part Name"] == "Group")}/>    
                                                }
                                                </>
                                                :
                                                <></>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {updating ?
                <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                    <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Please define the individual constraints. Individual constraints are parts assembled directly after each other. 
                        You can define multiple parts as individual constraints. You can also define multiple individual constraints, updating them individually.</p>
                    </div>
                    <Grid style={{float: "left", marginTop: "3%"}}>
                            <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                            <span className={classes.questionCircle}>?</span>
                            {isTooltipVisible && (
                            <div
                                className={classes.tooltip}>
                                Individual constraints can take the individual environments of the user for the sequence into account - 
                                information that might not be discovered in the CAD data. 
                                This might be relevant for e.g. assembly operations that should be grouped or individual assembly line layout constraints.
                            </div>)}
                        </a>
                        </Grid>
                    <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => nextStep(7)} mt={2} color="primary" type="button" variant="contained">Next step</Button>
                    <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => updateConstraints()} mt={2} color="primary" type="button" variant="contained">Update</Button>
                </Grid>
                :
                    <ReactBootStrap.Spinner animation="border" style={{marginRight: '5%', marginTop: '2%', float: 'right'}}/> 
                }
            </div>
        }
        </div>
    )
};

export default IndividualConstraints;
