import _, { filter, functionsIn } from 'lodash';
import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Grid, 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Input
} from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AuthContext from '../context/AuthContext'
import * as ReactBootStrap from 'react-bootstrap';
import useStyles from './styles/CommonStyles';
const PartTypeRecognition = ({onPageProps, updateHeaderStage, redirectErrorProps}) => {
    let [loading, setLoading] = useState(true)
    let [updating, setUpdating] = useState(true)
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [authToken, setAuthToken] = useState('')
    let {authentificationToken} = useContext(AuthContext)

    let [partTypeRecognitionMatrix, setPartTypeRecognitionMatrix] = useState([])
    let [startSocket, setStartSocket] = useState(false)
    let [progressMessage, setProgressMessage] = useState("")
    let [selectedTypes, setSelectedTypes] = useState({})
    let [assemblies, setAssemblies] = useState([])
    let [filterBy, setFilterBy] = useState('null')
    let [isTooltipVisible, setTooltipVisible] = useState(false)

    useEffect(() => {
        // Extract parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const authTokens = localStorage.getItem('authTokens');
        setAuthToken(authTokens)
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)

        const getPartTypeRecognitionStructure = async () => {
            var savedData = JSON.parse(localStorage.getItem("savedData"))
            var currentDoc = savedData[documentId]

            // start websocket connection
            requestProcess(documentId, "token")

            try{
                const response = await axios.patch('/api/get-parttype-recognition-structure/', JSON.stringify(currentDoc),
                {
                    params: {
                        'documentId': documentId,
                        'workspaceId': workspaceId,
                        'elementId': elementId,
                        'authTokens': authTokens
                    }
                })
                .then((response) => {
            })
            }catch(error){
                console.log(error)
                if (error.response === undefined){
                    var message = error.message
                    var status = error.status
                } else{
                    var message = error.response.data["message"]
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    var status = error.response.status
                }
                redirectErrorProps(message, status, "Part type recognition")
                setLoading(false)
            }
        }
        getPartTypeRecognitionStructure();

    }, [])

    const requestProcess = (docId, token) => {
        try{
            if (window.location.protocol == 'http:'){
                var url = `ws://${window.location.host}/ws/part-type-recognition/${docId}/`
            } else{
                var url = `wss://${window.location.host}/ws/part-type-recognition/${docId}/`
            }
            const websocket = new WebSocket(
            url, ["Token", token]
            );
            setStartSocket(true)
            
            websocket.onmessage = function (e) {
                let data = JSON.parse(e.data);
                if (data.type === 'connection_established' || data.type === 'progress') {
                    console.log(data.message)
                    setProgressMessage(data.message)
                } else if (data.type === 'completed') {
                    var savedData = JSON.parse(localStorage.getItem("savedData"))
                    var currentDoc = data.response["savedData"]
                    savedData[docId] = currentDoc
                    localStorage.setItem("savedData", JSON.stringify(savedData))
                    setPartTypeRecognitionMatrix(data.response["response"])
                    formPartTypesList(data.response["response"])
                    setLoading(false);
                    setTimeout(() => {
                        collapseRows("");
                        collapseRows("")
                    }, 20)
                    setFormControlOffset()

                    // remove event listener from a tooltip
                    var tip = document.getElementById("tipp")
                    if (tip != undefined){
                        function clickable(){console.log("click")}
                        tip.addEventListener('click', clickable)
                        tip.removeEventListener('click', clickable)
                    }
                    websocket.close()
                } else if (data.type === 'error') {
                    console.log(data.message)
                    var message = data.message
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, 500, "Part type recognition")
                    setLoading(false)
                    websocket.close()
                }
            };
        }catch(error){
            console.log(error)
            var message = error.message
            redirectErrorProps(message, error.status, "Part type recognition")
            setLoading(false)
        }
    };


    const classes = useStyles();

    function setFormControlOffset(){
        let partNameHeader = document.getElementById("PartName")
        let itemHeader = document.getElementById("Item")
        let categoryHeader = document.getElementById("PartCategory")
        let offset = itemHeader.offsetWidth + partNameHeader.offsetWidth + Math.max(0, (categoryHeader.offsetWidth - 200) / 2)
        document.getElementById("formControlContainer").style.marginLeft = offset + "px"
        document.getElementById("formControl").style.width = categoryHeader.offsetWidth + "px"

    }

    function formPartTypesList(data){
        var res = {}
        var groups = []
        data.forEach((elem) => {
            if (elem["Part Name"] != "Group"){
                res[elem["Part Number"]] = elem["Type"]
            } else{
                groups.push(elem["Part Number"])
            }
        })
        setSelectedTypes(res)
        setAssemblies(groups)
    }

    function checkEmpty(){
        assemblies.forEach(
            (group) => {
                var row = document.getElementById("Row_" + group);
                var button = document.getElementById(row.dataset.buttonid);
                var elems = document.getElementsByName("Row_" + row.dataset.buttonid.split("Collapse_")[1])
                button.style.visibility = "hidden"
                for (const elem of elems) {
                    if (selectedTypes[elem.id.split("Row_")[1]] == filterBy || filterBy == "null" || elem.dataset.isgroup == "true"){
                        button.style.visibility = "visible"
                        break
                    }
                }
            }
        )
    }

    function collapseRows(group, all=0){
        var target = document.getElementsByName("Row_" + group);
        var expandButton = document.getElementById("Collapse_" + group);
        var container = document.getElementById("ValueCollapse_" + group);
        if (container.title == "-"){
            expandButton.innerHTML = "↪";
            container.title = "+";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "collapse"
                    if (node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "-"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        } else {
            expandButton.innerHTML = "⤵";
            container.title = "-";
            target.forEach(
                function(node, index) {
                    if (selectedTypes[node.id.split("Row_")[1]] == filterBy || filterBy == "null" || node.dataset.isgroup == "true"){
                        node.style.visibility = "visible"
                    }
                    if (all && node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "+"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        }
        checkEmpty()
    }

    function nextStep(stage){
        updateHeaderStage(stage);
        onPageProps(stage);
    }

    function setPartType(evt, id){
        let res = {...selectedTypes}
        res[id] = evt.target.value
        setSelectedTypes(res)
    }

    function filterTypes(value){
        for (const [partId, type] of Object.entries({...selectedTypes})) {
            var row = document.getElementById("Row_" + partId)
            if (type != value && value != "null" && row.dataset.isgroup == "false"){
                row.style.visibility = "collapse"
            }
          }
        setFilterBy(value)
        let btn = document.getElementById("Collapse_")
        setTimeout(() => {btn.click(); btn.click()}, 50)
    }

    const updatePartTypes = async () => {
        setUpdating(false)
        var savedData = JSON.parse(localStorage.getItem("savedData"))[documentId]

        try{
            await axios.post('/api/get-parttype-recognition-structure/', JSON.stringify(savedData),
            {
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'selectedTypes': [{...selectedTypes}]
                }
            }).then((response) => {
                var savedData = JSON.parse(localStorage.getItem("savedData"))
                var currentDoc = response.data["savedData"]
                savedData[documentId] = currentDoc
                localStorage.setItem("savedData", JSON.stringify(savedData))
                filterTypes(filterBy)
                console.log("successfully updated")
                setUpdating(true)});
            } catch (e){
                if (e.response == undefined){
                    alert("Failed to update part types. \n\n" + e.message)
                }
                else{
                    alert("Failed to update part types. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
                }
                setUpdating(true) 
            }
        }

    return (
        <div className="subassembly-structure-main" style={{ marginLeft: '3%', marginRight: '3%', marginTop:'6%'}}>
        {loading ?
            <>
                <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '50%',marginTop:'25%'}} />
                <Grid item xs={12} align="center" style={{marginTop:'5px'}}>
                    <div> {progressMessage}</div>
                </Grid>

            </>
            :
            <div className="subassembly-structure-table" style={{ width: '100%'}}>
                <TableContainer className="matrixdata" style={{marginTop:'6%'}} spacing={1} component={Paper}>
                    <Grid className={classes.container} id="formControlContainer">
                        <FormControl className={classes.formControl} id="formControl">
                            <InputLabel>Filter by category</InputLabel>
                            <Select value={filterBy} displayEmpty onChange={(evt) => {filterTypes(evt.target.value)}}>
                                <MenuItem value="null"><div style={{margin: "auto"}}>All</div></MenuItem>
                                <MenuItem value="-"><div style={{margin: "auto"}}>-</div></MenuItem>
                                <MenuItem value="Screw"><div style={{margin: "auto"}}>Screw</div></MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Table>
                        <TableHead>
                            <TableRow className="matrixhead" xs={12}>
                                <TableCell className={classes.tableHeaderCell} id="Item">
                                    <div id="ValueCollapse_" title="-"></div>
                                    <a id="Collapse_" onClick={(evt) => collapseRows("")} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                    <span>Item</span>
                                </TableCell>
                                <TableCell className={classes.tableHeaderCell} id="PartName">Part Name</TableCell>
                                <TableCell className={classes.tableHeaderCell} id="PartCategory">Part Category</TableCell>
                                <TableCell className={classes.tableHeaderCell} id="Thumbnail">Thumbnail
                                    <a id="Collapse_All" onClick={(evt) => collapseRows("", 1)} type='button' style={{paddingLeft: '15px'}} title="Hide/Expand all">⏏</a> 
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                _.map(partTypeRecognitionMatrix, (r,v,k) =>
                                        <>
                                        {true ?
                                        <TableRow id={"Row_" + r["Part Number"]} xs={12} name={"Row_" + r["Collapse"]} data-isgroup={r["Part Name"] == 'Group'} data-buttonid={"Collapse_" + r["Assembly Group"]}>
                                            <TableCell className={classes.tableCell}>
                                                {r["Part Name"] == 'Group' ?
                                                    <span>
                                                    <div id={"ValueCollapse_" + r["Assembly Group"]} title="-"></div>
                                                    <a id={"Collapse_" + r["Assembly Group"]} onClick={(evt) => collapseRows(r["Assembly Group"])} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                                    {r["Item"]}
                                                </span>
                                                :
                                                <span style={{paddingLeft: '30px'}}>{r["Item"]}</span>
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{paddingLeft: "9%"}}>
                                                <div style={{paddingLeft: 16 + 15 * r["Level"] + "px", textAlign: "left"}}>
                                                    {r["Part Name"] == 'Group' ?
                                                        r["Part Number"]
                                                    :
                                                        r["Part Name"]
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {r["Part Name"] == 'Group' ?
                                                    "Group"
                                                :
                                                <FormControl style={{width: "100%", maxWidth: "150px"}}>
                                                    <Select id={"Type_" + r["Part Number"]} value={selectedTypes[r["Part Number"]]} displayEmpty onChange={(evt) => setPartType(evt, r["Part Number"])}>
                                                        <MenuItem value="-">-</MenuItem>
                                                        <MenuItem value="Screw">Screw</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                }
                                            </TableCell>
                                            <TableCell className={classes.tableCell}><img src={"/api/get-private-content?url=" + encodeURIComponent(r["Thumbnail"])+"&authTokens=" +encodeURIComponent(authToken)} /></TableCell>
                                        </TableRow>
                                        :
                                        <></>
                                        }
                                        </>
                                    )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {updating ?
                    <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                        <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Please check on AssemblyWise part type recognition. If the prediction is wrong, please correct it and click update.</p>
                        </div>
                        <Grid style={{float: "left", marginTop: "3%"}}>
                            <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                            <span className={classes.questionCircle}>?</span>
                            {isTooltipVisible && (
                            <div
                                className={classes.tooltip}>
                                Parts like screws are always joined after the joined element. 
                                Moving elements are mostly also joined after each other due to tolerance issues. 
                                At the moment AssemblyWise can only predict screws. 
                            </div>)}
                        </a>
                        </Grid>
                        <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => nextStep(5)} mt={2} color="primary" type="button" variant="contained">Next step</Button>
                        <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => updatePartTypes()} mt={2} color="primary" type="button" variant="contained">Update</Button>
                    </Grid>
                :
                    <ReactBootStrap.Spinner animation="border" style={{marginRight: '5%', marginTop: '2%', float: 'right'}}/> 
                }
            </div>
        }
        </div>
    )
};

export default PartTypeRecognition;