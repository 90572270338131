import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from '../context/AuthContext'
import * as ReactBootStrap from 'react-bootstrap';
import useStyles from './styles/CommonStyles';

const PartStudioSelectionPage = ({ onPageProps, updateHeaderStage, redirectErrorProps }) => {
    const history = useHistory();
    let [loading, setLoading] = useState(false)
    const [partStudioExists, setPartStudioExists] = useState('');
    const [selectedPartStudio, setSelectedPartStudio] = useState('');
    const [partStudioList, setPartStudioList] = useState([]);
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [authToken, setAuthToken] = useState('')
    let [refreshToken, setRefreshToken] = useState('')
    let [isTooltipVisible, setTooltipVisible] = useState(false)

    let {getRefreshToken} = useContext(AuthContext)
    console.log("Refresh tokens data :",getRefreshToken);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const authTokens = localStorage.getItem('authTokens');
        const refreshTokens = localStorage.getItem('refresh_token')
        setAuthToken(authTokens)
        setRefreshToken(refreshTokens)
        if(code && !refreshTokens) {
            let res = getRefreshToken(code);
            console.log("Testing");
        }
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const docId = stateParams.get('documentId');
        setDocumentId(docId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)

    }, [])

    const handlePartStudioExistsChange = async (event) => {
        setPartStudioExists(event.target.value);
        if (event.target.value === 'yes') {
        const authTokens = localStorage.getItem('authTokens');
        const refreshTokens = localStorage.getItem('refresh_token')
        setAuthToken(authTokens)
        setRefreshToken(refreshTokens)
            setLoading(true);
            try{
                const response = await axios.get('/api/get-partstudio-list/', {
                    params: {
                        'documentId': documentId,
                        'workspaceId': workspaceId,
                        'authTokens': authTokens,
                        'refreshTokens': refreshTokens,
                    }
                });
                setPartStudioList(response.data)
                setLoading(false);
            }catch(error){
                console.log(error)
                if (error.response === undefined){
                    var message = error.message
                    var status = error.status
                } else{
                    var message = error.response.data["message"]
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    var status = error.response.status
                }
                redirectErrorProps(message, status, "Part Studio Selection")
                setLoading(false)
            }
        }
    };

    const handlePartStudioSelect = (event) => {
        setSelectedPartStudio(event.target.value);
    };
    const startPreparation = (event) => {
        console.log('Starting preparation...');
        history.replace('/preparation');
    };

    const startAssemblySequence = (event) => {
        localStorage.setItem('partStudio', selectedPartStudio)
        console.log('Starting assembly sequence generation...');
        updateHeaderStage(3)
        onPageProps(3)
    };

    const classes = useStyles();

    return (
        <div xs={12} style={{marginTop: '25%', textAlign: 'center'}}>
            <div>
                <FormControl className={classes.formControlConfig}>
                    <InputLabel>Does a Single Part Studio already exist?</InputLabel>
                    <Select value={partStudioExists} onChange={handlePartStudioExistsChange}>
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {partStudioExists == "yes" && (
                <div>
                    <FormControl className={classes.formControlConfig}>
                        <InputLabel>Select Part Studio</InputLabel>
                        <Select value={selectedPartStudio} onChange={handlePartStudioSelect}>
                        {partStudioList.map(partStudio => (
                            <MenuItem value={partStudio.id}>{partStudio.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </div>
            )}

            {loading ?
            <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '5px',marginTop:'5%'}} />
            :(partStudioExists == "no") ?
                <div className="create-new-objects" style={{ marginLeft: '10%', width: '80%', marginTop:'6%'}}>
                    <Grid container>
                        <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                            <Button style={{ marginTop: '10px', marginLeft: '5px'}} mt={2} onClick={(evt) => startPreparation()}  color="primary" type="button" variant="contained">
                                Start Preparation
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            :(partStudioExists == "yes" && selectedPartStudio != '') ?
                <div className="create-new-objects" style={{ marginLeft: '10%', width: '80%', marginTop:'6%'}}>
                    <Grid container>
                        <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                            <Button style={{ marginTop: '10px', marginLeft: '5px'}} mt={2} onClick={(evt) => startAssemblySequence()}  color="primary" type="button" variant="contained">
                                Start assembly sequence generation
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            :
                <></>
            }
            <div style={{bottom: '20%', marginLeft: "10%", position: "absolute", width: "70%"}}>
            <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Please provide the single part studio if available</p>
            </div>
            <Grid style={{float: "left", marginTop: "3%"}}>
                    <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                    <span className={classes.questionCircle}>?</span>
                    {isTooltipVisible && (
                    <div className={classes.tooltip} style={{top: "130%"}}>
                        AssemblyWise requires a single part studio to do the spatial calculations. A single part studio contains all parts in the final position of the overall assembly studio. 
                        If no single part studio exists, AssemblyWise can create it for you. Once the sequence is computed, the created single part studio can be deleted. 
                    </div>)}
                </a>
            </Grid>
            </div>

        </div>
    )
};

export default PartStudioSelectionPage;