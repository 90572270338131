import _ from 'lodash';
import axios from 'axios';
import React, { useState, useEffect} from 'react';
import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import * as ReactBootStrap from 'react-bootstrap';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import useStyles from './styles/CommonStyles';
const SubassemblyDivision = ({onPageProps, updateHeaderStage, updateShowHeaderProps, redirectErrorProps}) => {
    let [loading, setLoading] = useState(true)
    let [updating, setUpdating] = useState(true)
    let [updated, setUpdated] = useState(false)
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [authToken, setAuthToken] = useState('')
    let [refreshToken, setRefreshToken] = useState('')

    let [toCompute, setToCompute] = useState({})
    let [subassemblyMatrix, setSubassemblyMatrix] = useState([])
    let [startSocket, setStartSocket] = useState(false)
    let [isTooltipVisible, setTooltipVisible] = useState(false)

    useEffect(() => {
        // Extract parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const authTokens = localStorage.getItem('authTokens');
        const refreshTokens = localStorage.getItem('refresh_token')

        setAuthToken(authTokens)
        setRefreshToken(refreshTokens)
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)

        const getSubassemblyStructure = async () => {
            var savedData = JSON.parse(localStorage.getItem("savedData"))
            var currentDoc = savedData[documentId]

            // start websocket connection
            requestProcess(documentId, "token")

            try{
                await axios.patch('/api/get-subassembly-structure/', JSON.stringify(currentDoc), 
                {   headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        'documentId': documentId,
                        'workspaceId': workspaceId,
                        'elementId': elementId,
                        'authTokens': authTokens,
                        'refreshTokens': refreshTokens,
                        'partStudioId': localStorage.getItem("partStudio"),
                        'partStudioTemporary': localStorage.getItem('partStudioTemporary')
                    }
                })
                .then((response) => {})
            }catch(error){
                console.log(error)
                if (error.response === undefined){
                    var message = error.message
                    var status = error.status
                } else{
                    var message = error.response.data["message"]
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    var status = error.response.status
                }
                redirectErrorProps(message, status, "Subassembly division")
                setLoading(false)
            }
        }
        getSubassemblyStructure();

    }, [])

    const requestProcess = (docId, token) => {
        try{
            if (window.location.protocol == 'http:'){
                var url = `ws://${window.location.host}/ws/subassembly-division/${docId}/`
            } else{
                var url = `wss://${window.location.host}/ws/subassembly-division/${docId}/`
            }
            const websocket = new WebSocket(
            url, ["Token", token]
            );
            setStartSocket(true)
            
            websocket.onmessage = function (e) {
                let data = JSON.parse(e.data);
                if (data.type === 'connection_established' || data.type === 'progress') {
                    
                } else if (data.type === 'completed') {
                    var savedData = JSON.parse(localStorage.getItem("savedData"))
                    var currentDoc = data.response["savedData"]
                    savedData[docId] = currentDoc
                    localStorage.setItem("savedData", JSON.stringify(savedData))
                    setAuthToken(data.response["accessToken"])
                    updateShowHeaderProps(true)
                    setSubassemblyMatrix(data.response["response"])
                    setToCompute(formToCompute(data.response["response"]))
                    setLoading(false);
                    setTimeout(() => {
                        collapseRows("");
                        collapseRows("")
                    }, 20)

                    // remove event listener from a tooltip
                    var tip = document.getElementById("tipp")
                    if (tip != undefined){
                        function clickable(){console.log("click")}
                        tip.addEventListener('click', clickable)
                        tip.removeEventListener('click', clickable)
                    }
                    websocket.close()
                } else if (data.type === 'error') {
                    console.log(data.message)
                    var message = data.message
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, 500, "Subassembly division")
                    setLoading(false)
                    websocket.close()
                }
            };
        }catch(error){
            console.log(error)
            var message = error.message
            redirectErrorProps(message, error.status, "Subassembly division")
            setLoading(false)
        }
    };

    const classes = useStyles();

    function formToCompute(data){
        data.forEach(
            function(node, item){
                if (node["Part Name"] == "Group"){
                    toCompute[node["Part Number"]] = true
                }
            }
        )
        return toCompute
    }

    function collapseRows(group, all=0){
        var target = document.getElementsByName("Row_" + group);
        var expandButton = document.getElementById("Collapse_" + group);
        var container = document.getElementById("ValueCollapse_" + group);
        if (container.title == "-"){
            expandButton.innerHTML = "↪";
            container.title = "+";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "collapse"
                    if (node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "-"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        } else {
            expandButton.innerHTML = "⤵";
            container.title = "-";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "visible"
                    if (all && node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "+"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        }
    }

    function selectSubassemblies(partId, assemblyGroup = ""){
        toCompute[partId] = !toCompute[partId]
        var part = document.getElementById(partId + "_checkbox")
        var parts = document.getElementsByName("checkbox_" + assemblyGroup)
        parts.forEach(
            (p) => {
                if (part.checked != p.checked){
                    p.click()
                }
                })
    }

    const updateCompute = async () => {
        setUpdating(false)
        var savedData = JSON.parse(localStorage.getItem("savedData"))[documentId]
        try{
            await axios.post(`/api/get-subassembly-structure/`, JSON.stringify(savedData),
            { 
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'authTokens': authToken,
                    'toCompute': [toCompute]
                }})
                .then((response) => {
                    var savedData = JSON.parse(localStorage.getItem("savedData"))
                    var currentDoc = response.data["savedData"]
                    savedData[documentId] = currentDoc
                    localStorage.setItem("savedData", JSON.stringify(savedData))
                    console.log("successfully updated")
                    setUpdating(true)
                    setUpdated(true)});
        } catch (e){
            alert("Failed to update the subassemblies. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            setUpdating(true)
        }
    }

    function nextStep(stage){
        updateHeaderStage(stage);
        onPageProps(stage);
    }

    return (
        <div className="subassembly-structure-main" style={{ marginLeft: '3%', marginRight: '3%', marginTop:'6%'}}>
        {loading ?
                <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '50%',marginTop:'25%'}} />
            :
                <div className="subassembly-structure-table" style={{ width: '100%'}}>
                    <TableContainer className="matrixdata" style={{marginTop:'6%'}} spacing={1} component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow className="matrixhead" xs={12}>
                                    <TableCell className={classes.tableHeaderCell}>
                                        <div id="ValueCollapse_" title="-"></div>
                                        <a id="Collapse_" onClick={(evt) => collapseRows("")} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                        <span>Item</span>
                                        </TableCell>
                                    {/* <TableCell className={classes.tableHeaderCell}>Part Number</TableCell> */}
                                    <TableCell className={classes.tableHeaderCell}>Part Name</TableCell>
                                    <TableCell className={classes.tableHeaderCell}>
                                        <span>Thumbnail</span>
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>
                                            <span>Compute</span>
                                            <a id="Collapse_All" onClick={(evt) => collapseRows("", 1)} type='button' style={{paddingLeft: '15px'}} title="Hide/Expand all">⏏</a> 
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(subassemblyMatrix, (r,v,k) =>
                                            <TableRow xs={12} name={"Row_" + r["Collapse"]} data-isgroup={r["Part Name"] == 'Group'} data-buttonid={"Collapse_" + r["Assembly Group"]}>
                                                <TableCell className={classes.tableCell}>
                                                    {r["Part Name"] == 'Group' ?
                                                        <span>
                                                            <div id={"ValueCollapse_" + r["Assembly Group"]} title="-"></div>
                                                            <a id={"Collapse_" + r["Assembly Group"]} onClick={(evt) => collapseRows(r["Assembly Group"])} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                                            {r["Item"]} 
                                                        </span>
                                                    :
                                                        <span style={{paddingLeft: '30px'}}>{r["Item"]}</span>
                                                    }
                                                </TableCell>
                                                {/* <TableCell>{r["Part Number"]}</TableCell> */}
                                                <TableCell className={classes.tableCell} style={{paddingLeft: "11%"}}>
                                                        <div style={{paddingLeft: 16 + 15 * r["Level"] + "px", textAlign: "left"}}>
                                                            {r["Part Name"] == 'Group' ?
                                                                r["Part Number"]
                                                            :
                                                                r["Part Name"]
                                                            }
                                                        </div>
                                                </TableCell>
                                                <TableCell className={classes.tableCell}><img src={"/api/get-private-content?url=" + encodeURIComponent(r["Thumbnail"])+"&authTokens=" +encodeURIComponent(authToken)} /></TableCell>
                                                <TableCell className={classes.tableCell}>
                                                    {r["Part Name"] == "Group" && r["Compute"] ?
                                                        <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkbox_" + r["Collapse"]} classes={{
                                                                root: classes.customCheckbox
                                                            }} onChange={(evt) => selectSubassemblies(r["Part Number"], r["Assembly Group"])} defaultChecked/>
                                                    : r["Part Name"] == "Group" ?
                                                        <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkbox_" + r["Collapse"]} classes={{
                                                                root: classes.customCheckbox
                                                            }} onChange={(evt) => selectSubassemblies(r["Part Number"], r["Assembly Group"])}/>
                                                    :
                                                        <></>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        </Table>
                </TableContainer>
                {
                updating && updated ?
                        <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} mt={2} onClick={() => nextStep(4)} color="primary" type="button" variant="contained">Next step</Button>
                : updating ?
                <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                    <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Please select the subassemblies that AssemblyWise should generate an assembly sequence for. </p>
                    </div>
                    <Grid style={{float: "left", marginTop: "3%"}}>
                            <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                            <span className={classes.questionCircle}>?</span>
                            {isTooltipVisible && (
                            <div
                                className={classes.tooltip}>
                                If you do not want to plan the assembly sequence for a certain subassembly, 
                                please deselect it and click update. Supplier parts such as bearings or 
                                PCB subassemblies might not be relevant for sequence planning as they are preassembled. 
                                Not considering those parts will reduce the computation time. 
                            </div>)}
                        </a>
                        </Grid>
                    <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => nextStep(4)} mt={2} color="primary" type="button" variant="contained">Next step</Button>
                    <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => updateCompute()} mt={2} color="primary" type="button" variant="contained">Update</Button>
                </Grid>
                :
                    <ReactBootStrap.Spinner animation="border" style={{marginRight: '5%', marginTop: '2%', float: 'right'}}/> 
                }
            </div>
        }
        </div>
    )
};

export default SubassemblyDivision;