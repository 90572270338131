import _ from 'lodash';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import * as ReactBootStrap from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import useStyles from './styles/CommonStyles';
const BaseComponentSelectionPage = ({onPageProps, updateHeaderStage, redirectErrorProps}) => {
    let [loading, setLoading] = useState(true)
    let [updating, setUpdating] = useState(true)
    let [BaseComponentSelectionMatrix, setBaseComponentSelectionMatrix] = useState([])
    let [documentId, setDocumentId] = useState('')
    let [workspaceId, setWorkspaceId] = useState('')
    let [elementId, setElementId] = useState('')
    let [authToken, setAuthToken] = useState('')
    let [baseComponents, setBaseComponents] = useState({})

    let [constraintProgress, setConstraintProgress] = useState(0)
    let [progressMessage, setProgressMessage] = useState("Getting the data...")
    let [constraintSubProgress, setConstraintSubProgress] = useState(0)
    let [subProgressMessage, setSubProgressMessage] = useState("")
    let [startSocket, setStartSocket] = useState(false)
    let [isTooltipVisible, setTooltipVisible] = useState(false)

    useEffect(() => {
        // Extract parameters from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        const authTokens = localStorage.getItem('authTokens');
        setAuthToken(authTokens)
        // Decode and extract the documentId, workspaceId, and elementId from the state parameter
        const decodedState = decodeURIComponent(state);
        const stateParams = new URLSearchParams(decodedState);
        const documentId = stateParams.get('documentId');
        setDocumentId(documentId)
        const workspaceId = stateParams.get('workspaceId');
        setWorkspaceId(workspaceId)
        const elementId = stateParams.get('elementId');
        setElementId(elementId)

        const getBaseComponents = async () => {
            var savedData = JSON.parse(localStorage.getItem("savedData"))
            var currentDoc = savedData[documentId]
            requestProcess(documentId, "token")
            try{
                await axios.patch('/api/get-base-components/', JSON.stringify(currentDoc), 
                {   headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        'documentId': documentId,
                        'workspaceId': workspaceId,
                        'elementId': elementId,
                        'authTokens': authTokens,
                    }
                })
                .then((response) => {
            })
            }catch(error){
                console.log(error)
                if (error.response === undefined){
                    var message = error.message
                    var status = error.status
                } else{
                    var message = error.response.data["message"]
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    var status = error.response.status
                }
                redirectErrorProps(message, status, "Base component selection")
                setLoading(false)
            }
        }
        getBaseComponents();


    }, [])

    const requestProcess = (docId, token) => {
        try{
            if (window.location.protocol == 'http:'){
                var url = `ws://${window.location.host}/ws/base-component-selection/${docId}/`
            } else{
                var url = `wss://${window.location.host}/ws/base-component-selection/${docId}/`
            }
            const websocket = new WebSocket(
            url, ["Token", token]
            );
            setStartSocket(true)
            
            websocket.onmessage = function (e) {
                let data = JSON.parse(e.data);
                handleWebSocketMessage(data);
                   };
            

                console.log('WebSocket connection initiated');
                websocket.onopen = () => {
                    console.log('WebSocket connection established');
                    setStartSocket(true)
                };
                
                websocket.onmessage = async function (e) {
                    let data = JSON.parse(e.data);
                    await handleWebSocketMessage(data);
                    };
    
                websocket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
            
                websocket.onclose = () => {
                    console.log('WebSocket connection closed');
                };       

            const handleWebSocketMessage = (data) => {

                   if (data.type === 'connection_established' || data.type === 'progress') {
                    if (!data.stage){
                        setConstraintProgress(data.progress)
                        setProgressMessage(data.message)
                    } else{
                        setConstraintSubProgress(data.progress)
                        setSubProgressMessage(data.message)
                    }
                } else if (data.type === 'completed') {
                    var savedData = JSON.parse(localStorage.getItem("savedData"))
                    var currentDoc = data.response["savedData"]
                    savedData[docId] = currentDoc
                    localStorage.setItem("savedData", JSON.stringify(savedData))
                    setBaseComponentSelectionMatrix(data.response["response"])
                    setBaseComponents(formBaseComponents(data.response["response"]))
                    setLoading(false);
                    setTimeout(() => {
                        collapseRows("");
                        collapseRows("")
                    }, 20)

                    var tip = document.getElementById("tipp")
                    if (tip != undefined){
                        function clickable(){console.log("click")}
                        tip.addEventListener('click', clickable)
                        tip.removeEventListener('click', clickable)
                    }
                    websocket.close()
                } else if (data.type === 'error') {
                    console.log(data.message)
                    var message = data.message
                    if (message == undefined){
                        message = "unknown exception"
                    }
                    redirectErrorProps(message, 500, "Base component selection")
                    setLoading(false)
                    //websocket.close()
                }
                
            }
         
        }catch(error){
            console.log(error)
            var message = error.message
            redirectErrorProps(message, error.status, "Base component selection")
            setLoading(false)
        }
    };

    const classes = useStyles();

    function formBaseComponents(data){
        data.forEach(
            function(node, index){
                baseComponents[node["Part Number"]] = {
                    "base": false,
                    "assembly": node["Assembly Parent"],
                    "item": node["Item"],
                    "isGroup": node["Part Name"] == "Group"
                }
            }
        )
        return baseComponents
    }

    function selectBaseComponent(partId, groupId){
        var part = document.getElementById(partId + "_checkbox")
        var parts = document.getElementsByName("checkFor_" + groupId)

        baseComponents[partId]["base"] = true
        if (part.checked){
            parts.forEach(
                function(node, index) {
                    if (node.id != partId + "_checkbox" && node.checked){
                        node.click()
                        baseComponents[node.id.split('_checkbox')[0]]["base"] = false
                    }
                });
            }
        else{
            baseComponents[partId]["base"] = false
        }
    }

    const updateBaseComponents = async () => {
        setUpdating(false)
        var savedData = JSON.parse(localStorage.getItem("savedData"))[documentId]

        var send = {}
        for (const [partId, details] of Object.entries(baseComponents)) {
            if (details["base"]){
                send[partId] = details
            }
          }
        try{
            await axios.post('/api/get-base-components/', JSON.stringify(savedData),
            {
                params: {
                    'documentId': documentId,
                    'workspaceId': workspaceId,
                    'elementId': elementId,
                    'authTokens': authToken,
                    'baseComponents': [send]
                }
            }).then((response) => {
                var savedData = JSON.parse(localStorage.getItem("savedData"))
                var currentDoc = response.data["savedData"]
                savedData[documentId] = currentDoc
                localStorage.setItem("savedData", JSON.stringify(savedData))
                setBaseComponentSelectionMatrix(response.data["response"])
                formBaseComponents(response.data["response"])
                let btn = document.getElementById("Collapse_")
                setTimeout(() => {btn.click(); btn.click()}, 50)
                console.log("successfully updated")
            setUpdating(true)});
        } catch (e){
            alert("Failed to update base components. \n\nError with status " + e.response.status + ":\n" + e.response.data["message"])
            setUpdating(true)
        }
    }

    function nextStep(stage){
        updateHeaderStage(stage);
        onPageProps(stage);
    }

    function collapseRows(group, all=0){
        var target = document.getElementsByName("Row_" + group);
        var expandButton = document.getElementById("Collapse_" + group);
        var container = document.getElementById("ValueCollapse_" + group);
        if (container.title == "-"){
            expandButton.innerHTML = "↪";
            container.title = "+";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "collapse"
                    if (node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "-"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        } else {
            expandButton.innerHTML = "⤵";
            container.title = "-";
            target.forEach(
                function(node, index) {
                    node.style.visibility = "visible"
                    if (all && node.dataset.isgroup == "true"){
                        var btn = document.getElementById(node.dataset.buttonid);
                        var cntnr = document.getElementById("Value" + node.dataset.buttonid);
                        if (cntnr.title == "+"){
                            collapseRows(btn.id.split("_")[1]);
                        }
                    }
                });
        }
    }

    return (
        <div className="part-type-recognition-main" style={{ marginLeft: '1%', width: '98%', marginTop:'6%'}}>
        {loading ?
            <>
                {!startSocket ?
                    <ReactBootStrap.Spinner animation="border" style={{ marginLeft: '50%',marginTop:'25%'}} />
                :
                    <Grid className={classes.progressGrid} item xs={12} align="center" style={{marginBottom:'5px'}}>
                        <div> {progressMessage}</div>
                        <div className={classes.progressBarDiv}><ProgressBar className={classes.progressBarMain} animated now={constraintProgress} variant="success" label={constraintProgress + "%"}/></div>
                        { subProgressMessage ?
                            <>
                                <div>{subProgressMessage}</div>
                                <div className={classes.progressBarDiv}><ProgressBar className={classes.progressBarMain} animated now={constraintSubProgress} variant="warning" label={constraintSubProgress + "%"}/></div>
                            </>
                        :
                            <></>
                        }
                    </Grid>
                }
            </>
            :
            <div className="part-type-recognition-table" style={{ marginLeft: '1%', width: '98%'}}>
                <TableContainer className="matrixdata" style={{marginTop:'6%'}} spacing={1} component={Paper}>
                        {/* <Button color="primary" type="button" onClick={(evt) => startDownload("media/tables/" + documentId + "_structure.xlsx", "product_structure.xlsx")}>Export current table</Button> */}
                        <a id="Collapse_All" onClick={(evt) => collapseRows("", 1)} type='button' style={{paddingRight: '2%', paddingTop: '5px', float: 'right'}} title="Hide/Expand all">⏏</a> 
                        <Table>
                            <TableHead>
                                <TableRow className="matrixhead" xs={12}>
                                    <TableCell className={classes.tableHeaderCell}>
                                        <div id="ValueCollapse_" title="-"></div>
                                        <a id="Collapse_" onClick={(evt) => collapseRows("")} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                        <span>Item</span>
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Part Name</TableCell>
                                    {/* <TableCell className={classes.tableHeaderCell}>Part Number</TableCell> */}
                                    <TableCell className={classes.tableHeaderCell}>
                                        <span>Thumbnail</span>
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCell}>Base component</TableCell>
                                    {/* <TableCell className={classes.tableHeaderCell}>Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(BaseComponentSelectionMatrix, (r,v,k) =>
                                            <TableRow xs={12} name={"Row_" + r["Collapse"]} data-isgroup={r["Part Name"] == 'Group'} data-buttonid={"Collapse_" + r["Assembly Group"]}>
                                                <TableCell className={classes.tableCell}>
                                                    {r["Part Name"] == 'Group' ?
                                                        <span>
                                                            <div id={"ValueCollapse_" + r["Assembly Group"]} title="-"></div>
                                                            <a id={"Collapse_" + r["Assembly Group"]} onClick={(evt) => collapseRows(r["Assembly Group"])} type='button' style={{paddingRight: '15px'}}>⤵</a> 
                                                            {r["Item"]} 
                                                        </span>
                                                    :
                                                        <span style={{paddingLeft: '30px'}}>{r["Item"]}</span>
                                                    }
                                                </TableCell>
                                                <TableCell className={classes.tableCell} style={{paddingLeft: "11%"}}>
                                                    <div style={{paddingLeft: 16 + 15 * r["Level"] + "px", textAlign: "left"}}>
                                                        {r["Part Name"] == 'Group' ?
                                                            r["Part Number"]
                                                        :
                                                            r["Part Name"]
                                                        }
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell>{r["Part Number"]}</TableCell> */}
                                                <TableCell className={classes.tableCell}><img src={"/api/get-private-content?url=" + encodeURIComponent(r["Thumbnail"])+"&authTokens=" +encodeURIComponent(authToken)} /></TableCell>
                                                <TableCell className={classes.tableCell} style={{textAlign: 'center'}}>
                                                    {updating ?
                                                        <>
                                                            {r["Base"] ?
                                                            <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkFor_" + r["Assembly Parent"]} classes={{
                                                                root: classes.customCheckbox,
                                                            }} checked disabled
                                                            onChange={(evt) => selectBaseComponent(r["Part Number"], r["Assembly Parent"])}/>
                                                            :
                                                            <Checkbox id={r["Part Number"]+"_checkbox"} name={"checkFor_" + r["Assembly Parent"]} classes={{
                                                                root: classes.customCheckbox
                                                            }}
                                                            onChange={(evt) => selectBaseComponent(r["Part Number"], r["Assembly Parent"])}/>}
                                                        </>
                                                    :
                                                    <></>
                                                    }
                                                </TableCell>
                                                {/* <TableCell className={classes.tableCell}style={{textAlign: 'center', color: 'red', cursor: 'pointer'}}>
                                                    <DeleteIcon data-testid="DeleteIcon" />
                                                </TableCell> */}
                                            </TableRow>
                                        )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {updating ?
                    <Grid item xs={12} align="center" style={{marginBottom:'5px'}}>
                        <div className={classes.userTips}>
                        <p className={classes.userTipsText}>Please check on the base component selected by the algorithm. If the base component is wrong, please update the selection.</p>
                        </div>
                        <Grid style={{float: "left", marginTop: "3%"}}>
                            <a id="tipp" className={classes.questionButton} role="button" onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                            <span className={classes.questionCircle}>?</span>
                            {isTooltipVisible && (
                            <div
                                className={classes.tooltip}>
                                The starting part is important for industrial sequences. AssemblyWise selects the base component based on the size and the connections to other parts. 
                                If it is not applicable, the user can update the selection. 
                            </div>)}
                        </a>
                        </Grid>
                        <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => nextStep(6)} mt={2} color="primary" type="button" variant="contained">Next step</Button>
                        <Button style={{marginRight: '5%', marginTop: '2%', float: 'right'}} onClick={(evt) => updateBaseComponents()} mt={2} color="primary" type="button" variant="contained">Update</Button>
                    </Grid>
                    :
                    <ReactBootStrap.Spinner animation="border" style={{marginRight: '5%', marginTop: '2%', float: 'right'}}/> 
                    }
            </div>
        }
        </div>
    )
};

export default BaseComponentSelectionPage;