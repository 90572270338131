
import { UPLOAD_TRIGGERED } from './actions';

const initialState = {
    isUploadTriggered: false,
};

export const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_TRIGGERED:
            return {
                ...state,
                isUploadTriggered: action.payload,
            };
        default:
            return state;
    }
};
