
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { uploadReducer } from './reducer';

const rootReducer = combineReducers({
    upload: uploadReducer,
 
});

export const store = configureStore({
    reducer: rootReducer,
  });
