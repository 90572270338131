import { useState, useEffect } from 'react';
import { openDB } from 'idb';

export const useIndexedDB = () => {
  const [db, setDb] = useState(null);
  const [isDbReady, setIsDbReady] = useState(false);

  useEffect(() => {
    async function initDB() {
      try {
        console.log('Initializing IndexedDB');
        const dbInstance = await openDB('assemblywise', 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains('uploads')) {
              db.createObjectStore('uploads', { keyPath: 'id' });
            }
          },
        });
        console.log('IndexedDB initialized');
        setDb(dbInstance);
        setIsDbReady(true);
      } catch (error) {
        console.error('Database initialization failed:', error);
      }
    }
    initDB();
  }, []);

  const withTransaction = async (storeName, mode, callback) => {
    if (!isDbReady || !db) {
      console.error('Database not initialized or not ready');
      return null;
    }
    try {
      const tx = db.transaction(storeName, mode);
      const store = tx.objectStore(storeName);
      const result = await callback(store);
      await tx.done;
      return result;
    } catch (error) {
      console.error(`Transaction failed in ${storeName} store:`, error);
      return null;
    }
  };

  const clearIndexedDB = async () => {
    await withTransaction('uploads', 'readwrite', async (store) => {
      console.log('Clearing IndexedDB');
      await store.clear();
      console.log('IndexedDB cleared');
    });
  };

  const saveToIndexedDB = async (key, data) => {
    await withTransaction('uploads', 'readwrite', async (store) => {
      console.log('Saving to IndexedDB:', { key, data });
      await store.put({ id: key, data: data });
      console.log('Saved to IndexedDB');
    });
  };

  const loadFromIndexedDB = async (key) => {
    return await withTransaction('uploads', 'readonly', async (store) => {
      console.log('Loading from IndexedDB:', key);
      const result = await store.get(key);
      console.log('Loaded from IndexedDB:', result);
      return result ? result.data : null;
    });
  };

  return { saveToIndexedDB, loadFromIndexedDB, isDbReady, clearIndexedDB };
};
